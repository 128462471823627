import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import RubyText from "../../../components/RubyText.tsx";
import Button from "../../../components/Button.tsx";
import axios from "axios";
import { useState } from "react";
import common from '../../../common/common.ts';
import Profile from "../../../components/Profile.tsx";

const DataContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:space-between;
`;
const DataRow = styled.div`
    width: 25%;
    height: 100%;
    margin:2em;
    text-align:left;
    @media (max-width: 900px) {
        margin:5px;
    }
`;
const DataHeader = styled.div`
    width: max-content;
    padding:2px 20px 2px 20px;
    background: #7bf37fce;
    color:#000;
    border-radius: 50px;
    border: 2px solid #fff;
    margin-bottom: 10px;
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
    
`;
const DataValue = styled.div`
    width: 100%;
    padding:2px 20px 2px 20px;
    border-bottom: 2px solid #000;
    font-size: 1rem;
    margin-top: 1em;
    margin-bottom: 1em;
    justify-content: space-between;
    display: flex;
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
`;
const UserRecord = () => {
    const navigate = useNavigate();
    const [personalRecords, setPersonalRecords] = useState([]);
    useEffect(() => {
        const getPersonalRecord = async () => {
            // APIからデータを取得
            const response = await axios.get(common.addAccessToken(process.env.REACT_APP_API_RECORD_PERSONAL_GET));
            if (response) {
                // courseRecordsを更新
                setPersonalRecords(response.data.data);
            }
        };
        // コンポーネントがマウントされた時に記録情報を取得
        getPersonalRecord();
    }, []);

    const moveSetting = () => {
        // 設定画面に遷移
        navigate("/user-setting");
    };
    return (
        <>
        <Profile />
        <DataContent>
            {
                !personalRecords &&
                <p>読み込み中...</p>
            }
            <DataRow>
                <DataHeader>プロフィール</DataHeader>
                <DataValue><span className="Label"><RubyText text="身長{しんちょう}" /></span><span className="Value">{personalRecords.height}cm</span></DataValue>
                <DataValue><span className="Label"><RubyText text="体重{たいじゅう}" /></span><span className="Value">{personalRecords.weight}kg</span></DataValue>
                <Button.Default className="warning small" onClick={()=>{moveSetting()}}><RubyText text="修正{しゅうせい}する"></RubyText></Button.Default>
                <DataValue><span className="Label"><RubyText text="所持{しょじ}ハピー" /></span><span className="Value">{personalRecords.point}ハピー</span></DataValue>
                <DataValue><span className="Label"><RubyText text="アイテム数{すう}" /></span><span className="Value">{personalRecords.asset_quantity}個</span></DataValue>
                <DataValue><span className="Label"><RubyText text="スタンプ数{すう}" /></span><span className="Value">{personalRecords.stamp_quantity}個</span></DataValue>
            </DataRow>
            <DataRow>
                <DataHeader>記録の合計</DataHeader>
                <DataValue><span className="Label"><RubyText text="記録{きろく}した数{かず}" /></span><span className="Value">{personalRecords.record_count}件</span></DataValue>
                <DataHeader>カレンダー</DataHeader>
                <DataValue><span className="Label"><RubyText text="連続日数{れんぞくにっすう}" /></span><span className="Value">{personalRecords.continue_days}日</span></DataValue>
                <DataValue><span className="Label"><RubyText text="最長連続日数{さいちょうれんぞくにっすう}" /></span><span className="Value">{personalRecords.longest_continue_days}日</span></DataValue>
            </DataRow>
            <DataRow>
                <DataHeader>コース別</DataHeader>
                <DataValue><span className="Label">ランキング</span><span className="Value">{personalRecords.ranking_count}回</span></DataValue>
                <DataValue><span className="Label">エンジョイ</span><span className="Value">{personalRecords.enjoy_count}回</span></DataValue>
                <DataValue><span className="Label">マイペース</span><span className="Value">{personalRecords.mypace_count}回</span></DataValue>
                <DataValue><span className="Label">はぴはぴ</span><span className="Value">{personalRecords.hapihapi_count}回</span></DataValue>
            </DataRow>
        </DataContent>
        </>
    );
}
export default UserRecord;