import React, { useState } from 'react';
import styled from 'styled-components';
import RubyText from '../../../components/RubyText.tsx';
import Message from '../../../components/Message.tsx';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

const LandItemContainer = styled.div`
    width: 90%;
    display: flex;
    background-color: #fff;
    overflow-x: scroll;
    margin-bottom: 5px;
    &::-webkit-scrollbar{
        width: 5px;
        margin-right: 10px;
        display:block;
    }
    &::-webkit-scrollbar-thumb{
        background-color: green;
        border-radius: 5px;
    }
`;
const ItemGrid = styled.div`
    min-width: 20%;
    border: 1px solid black;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
`;
const ItemImg = styled.div<{ src: string }>`
    width: 80%;
    height: 5em;
    margin: 5px;
    //srcがある場合は、srcを設定する
    background-image: url(${props => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;
const ItemAcquired = styled.span`
    display: block;
    background-color: #ecaa2e;
    width: 90%;
    margin: 5px;
    border-radius: 10px;
    color: #fff;
    font-size: small;
    rt{
        font-size: x-small;
    }
`;
const LandGridPreview = styled.table`
    width: 90%;
    height: 60%;
    border-collapse: collapse;
    border: 1px solid black;
    background-color: #00000078;
    td{
        border: 3px dotted #f8f8f8;
        width: 16%;
        text-align: center;
        color: #fff;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

    }
`;



export default function DraggableGridContainer({ userAssetItems, updateGridImages }: { userAssetItems: any, updateGridImages: any }) {
    //ランド設定結果の表示
    const [isLandSettingSuccess, setIsLandSettingSuccess] = useState<boolean>(false);
    const [isLandSettingFailed, setIsLandSettingFailed] = useState<boolean>(false);
    const [isLandSettingMessage, setIsLandSettingMessage] = useState<string>('');
    interface GridImages {
        [key: string]: string;
    };
    const [gridImages, setGridImages] = useState<GridImages>({
        x1y1: '', x2y1: '', x3y1: '', x4y1: '', x5y1: '', x6y1: '',
        x1y2: '', x2y2: '', x3y2: '', x4y2: '',
        x1y3: '', x2y3: '', x3y3: '', x4y3: '', x5y3: '', x6y3: '',
    });

    const messegeInit = () => {
        setIsLandSettingSuccess(false);
        setIsLandSettingFailed(false);
        setIsLandSettingMessage('');
    }
    const backendOptions = {
        enableMouseEvents: true, // マウスイベントも有効にする
        delayTouchStart: 0, // タッチ操作を開始するまでの遅延時間（ミリ秒）
    };
    const DraggableItemGrid = ({ item }) => {
        const [{ isDragging }, drag] = useDrag(() => ({
            type: "item",
            item: () => {
                messegeInit();
                return item;
            },
            collect: monitor => ({
                isDragging: !!monitor.isDragging(),
            }),
        }));

        return (
            <ItemGrid ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
                <ItemImg src={item.asset_path}></ItemImg>
                <ItemAcquired><RubyText text={`×${item.quantity}`}></RubyText></ItemAcquired>
            </ItemGrid>
        );
    };
    const DroppableTd = ({ gridId, onDrop }) => {
        const [{ isOver }, drop] = useDrop({
            accept: "item",
            drop: (item, monitor) => {
                onDrop(item, gridId);
            },
            collect: monitor => ({
                isOver: !!monitor.isOver(),
            }),
        });
        return (

            <td ref={drop} style=
                {
                    {
                        backgroundColor: isOver ? '#dff51f' : 'transparent',
                        backgroundImage: `url("${gridImages[gridId]}")`
                    }
                } id={gridId}></td>
        );

    };
    const CustomDragLayer = () => {
        const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }));

        if (!isDragging) {
            return null;
        }
        // ドラッグ中のアイテムをカスタムスタイルでレンダリング
        let asset_path = '';
        if (item) {
            asset_path = item.asset_path;
        }
        const DraggingElm = styled.div<{ offsetX: number, offsetY: number, asset_path: string }>`
            position:fixed;
            top:-10vh;
            left:-16vw;
            pointer-events: none;
            z-index: 100;
            width: 100px;
            height: 100px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(${props => props.asset_path});
            transform: translate(${props => props.offsetX}px, ${props => props.offsetY}px);
            -webkit-transform: translate(${props => props.offsetX}px, ${props => props.offsetY}px);
        `;

        return (
            <DraggingElm offsetX={currentOffset.x} offsetY={currentOffset.y} asset_path={asset_path}>
                {/* ドラッグ中のアイテムのカスタマイズされた表示 */}
            </DraggingElm>
        );
    };

    const handleDrop = (item, gridId) => {
        //DroppableTdを全件取得
        const droppableTd = document.querySelectorAll('.LandGridPreview td');
        //DroppableTdを全件ループしitem.asset_pathが設定されている件数を取得
        let count = 0;
        droppableTd.forEach(e => {
            if (e.style.backgroundImage == `url("${item.asset_path}")`) {
                count++;
            }
        });
        //item.quantity以上の場合はエラーメッセージを表示
        if (count >= item.quantity) {
            messegeInit();
            setIsLandSettingFailed(true);
            setIsLandSettingMessage(`${item.asset_name}は${item.quantity}個{こ}以上置{いじょうお}けません`);
            return;
        }
        const updatedGridImages = { ...gridImages, [gridId]: item.asset_path };
        setGridImages(updatedGridImages);
        updateGridImages(updatedGridImages);
    };

    return (
        <>
            <DndProvider backend={TouchBackend} options={backendOptions}>
                <CustomDragLayer />
                <LandItemContainer>
                    {userAssetItems &&
                        userAssetItems.map(e =>
                            <DraggableItemGrid key={e.asset_id} item={e}></DraggableItemGrid>
                        )
                    }
                </LandItemContainer>
                {
                    //エラーメッセージがあれば表示
                    isLandSettingFailed &&
                    <Message.Error><RubyText text={isLandSettingMessage}></RubyText></Message.Error>
                }
                <LandGridPreview className="LandGridPreview">
                    <tr>
                        <DroppableTd gridId="x1y1" onDrop={handleDrop}></DroppableTd>
                        <DroppableTd gridId="x2y1" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x3y1" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x4y1" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x5y1" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x6y1" onDrop={handleDrop} ></DroppableTd>
                    </tr>
                    <tr>
                        <DroppableTd gridId="x1y2" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x2y2" onDrop={handleDrop} ></DroppableTd>
                        <td colSpan={2}></td>
                        <DroppableTd gridId="x5y2" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x6y2" onDrop={handleDrop}></DroppableTd>
                    </tr>
                    <tr>
                        <DroppableTd gridId="x1y3" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x2y3" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x3y3" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x4y3" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x5y3" onDrop={handleDrop} ></DroppableTd>
                        <DroppableTd gridId="x6y3" onDrop={handleDrop} ></DroppableTd>
                    </tr>
                </LandGridPreview>
            </DndProvider>
        </>
    )
}