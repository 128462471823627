import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Competition } from '../models/Competition.ts';

/**
 * 種目プロバイダ公開項目
 */
const CompetitionContext = createContext<{
    competitions: Map<string, Map<Number, Competition>>,
}>({
    competitions: new Map(),
})

/**
 * 種目プロバイダ
 * @param props 描画するReact子要素
 * @returns 種目プロバイダ
 */
export const CompetitionProvider = (props: { children: React.ReactElement }) => {

    //全ての種目データ
    const [competitions, setCompetitions] = useState<Map<string, Map<Number, Competition>>>(new Map());

    //描画時にAPIサーバーから種目の一覧を取得する
    useEffect(() => {

        const startTime = performance.now();

        axios.get(`${process.env.REACT_APP_API_COMPETITION_GET}/${sessionStorage.getItem('accessToken')}`)
            .then((res) => {
                setCompetitions(res.data.data);

                const endTime = performance.now();
                const elapsedTime = endTime - startTime;

                console.log(`API request took ${elapsedTime} milliseconds`);
            })
            .catch(error => {
                console.log(error.request.response);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //プロバイダ描画
    return <CompetitionContext.Provider value={{ competitions }}>{props.children}</CompetitionContext.Provider>
}

export const useCompetition = () => useContext(CompetitionContext);