import React, { useEffect } from "react";
import styled from "styled-components";
import { ConfirmButton } from "../../components/ConfirmButton.tsx";
import { useNavigate, useLocation } from "react-router-dom";
import RubyText from "../../components/RubyText.tsx";
import Character from "../../components/Character.tsx";
import Modal from "../../components/Modal.tsx";
import Button from "../../components/Button.tsx";
import axios from "axios";
import { useState } from "react";
import common from '../../common/common.ts';
import { Asset } from '../../models/Asset.ts';
import Comfirm from "../../components/Comfirm.tsx";
import { PreviewContainer } from "./components/PreviewContainer.tsx";
import { PreviewGridContainer } from "./components/PreviewGridContainer.tsx";
import { UseCode } from "../../providers/CodeProvider.tsx";
import { UseParameter } from "../../providers/ParameterProvider.tsx";
import { UseUser, UseUserLand, UseUserAsset, UseUserRefresher } from "../../providers/UserProvider.tsx";
import Message from "../../components/Message.tsx";
import DraggableGridContainer from "./components/DraggableGridContainer.tsx";
import Particles from "react-tsparticles";


const PreviewArea = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 10px;
    position: absolute;
    top:0;
    left:0;
    //img要素を上下中央に配置
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 95%;
        max-height: 95%;
        //あすぺくと比率を維持
        object-fit: contain;
    }
`;

const ItemGridContainer = styled.div`
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    p{
        width: 100%;
        text-align: center;
    }
`;

const ItemGrid = styled.div`
    width: 20%;
    border: 1px solid black;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    margin: 5px;
    &.active{
        //afterでチェックマークを設定
        &:after{
            content: '✔';
            font-size: 1rem;
            text-align:left;
            color: #ffa52f;
            font-weight: bold;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 253, 118, 0.61);
        }
    }
`;
const ItemParticle = styled.div`
    width: 20%;
    border: 1px solid black;
    box-sizing: border-box;
    position: relative;
    background-color: #000000b2;
    margin: 5px;
    &.active{
        //afterでチェックマークを設定
        &:after{
            content: '✔';
            font-size: 1rem;
            text-align:left;
            color: #ffa52f;
            font-weight: bold;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 253, 118, 0.61);
        }
    }
`;


const ItemImg = styled.div<{ src: string }>`
    width: 80%;
    height: 5em;
    margin: 5px;
    //srcがある場合は、srcを設定する
    background-image: url(${props => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;
const ItemAcquired = styled.span`
    display: block;
    background-color: #ecaa2e;
    width: 90%;
    margin: 5px;
    border-radius: 10px;
    color: #fff;
`;
const SettingButton = styled.div<{ top?: number, left?: number, right?: number, bottom?: number }>`
    width: fit-content;
    color: black;
    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    border-radius: 50px;
    border: 3px dotted #13b91c;
    background-color: #fff;
    z-index: 999;
    position: absolute;
    top: ${props => props.top}%;
    left: ${props => props.left}%;
    right: ${props => props.right}%;
    bottom: ${props => props.bottom}%;
    @media (max-width: 900px) {
        font-size: 0.5rem;
        padding:0;
        padding-left: 10px;
        padding-right: 10px;
        
    }
    //beforeでカーソル画像を設定
    &:before{
        content: "";
        display: inline-flex;
        width: 1rem;
        height: 1rem;
        background-image: url(./img/cursor.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 10px;
    }
`;

const SaveButton = styled.div`
    width:10%;
    height: 10vw;
    background-image: url(./img/button-save.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 10px;
    right: 30%;
`;

const ModalButtonArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
`;
export const LandSetting = () => {
    //プレビュー用のランドデータ
    const [userLandPreview, setUserLandPreview] = useState<{}>({});
    //ディープコピー
    let userLand = JSON.parse(JSON.stringify(UseUserLand()));
    //ショップアイテムの取得
    const [userAssetItems, setUserAssetItems] = useState<Asset[]>([]);
    //選択したアイテムタイプ
    const [selectedAssetType, setSelectedAssetType] = useState<number>(0);
    //選択したアセットID
    const [selectedAssetID, setSelectedAssetID] = useState<number>(0);
    //パーティクルの設定
    const [particlesOptions, setParticlesOptions] = useState({});
    //パラメータの取得
    const params = UseParameter();

    //コードの取得
    const codes = UseCode();
    //ユーザーアセット情報の取得
    const userAsset = UseUserAsset();
    //ユーザー情報の更新
    const refreshUserData = UseUserRefresher();

    // モーダルの表示状態を管理するための状態変数
    const [isModalOpen, setIsModalOpen] = useState(false);
    //ランド設定結果の表示
    const [isLandSettingSuccess, setIsLandSettingSuccess] = useState<boolean>(false);
    const [isLandSettingFailed, setIsLandSettingFailed] = useState<boolean>(false);
    const [isLandSettingMessage, setIsLandSettingMessage] = useState<string>('');
    const messegeInit = () => {
        setIsLandSettingSuccess(false);
        setIsLandSettingFailed(false);
        setIsLandSettingMessage('');
    }

    //初期表示時に実行
    useEffect(() => {
        //userLandPreviewにuserLandを設定
        setUserLandPreview(userLand);
    }, []);

    //userLandが更新されたら実行
    useEffect(() => {
        if (userLand.length > 0) {
            setUserLandPreview(userLand);
            if (userLand.land_item) {
                Object.keys(userLand.land_item).forEach(key => {
                    console.log(key);
                    const td = document.getElementById(key);
                    if (!td) return;
                    td.setAttribute("asset_id", userLand.land_item[key]);
                    td.setAttribute("asset_path", userLand.land_item[key]);
                    td.style.backgroundImage = `url(${userLand.land_item[key]})`;
                });
            }
        }
    }, [userLand]);


    /**
     * アイテム詳細表示処理
     * @param id 
     */
    const SettingModal = (id: number) => {
        setSelectedAssetType(id);
        //codesからkubun:400,code:tabNoのデータを取得
        const code = codes.find(e => e.kubun === 400 && e.code === id);
        //asset_typeで絞り込み
        const items = userAsset[code.kanren1];
        if (id === 5) {
            if (items) {
                items.forEach(e => {
                    getParticlesOptions(e.asset_id, e.asset_path);
                });
            }
        }
        setUserAssetItems(items);
        //メッセージ部分を初期化
        messegeInit();
        //モーダルを開く
        setIsModalOpen(true);
    }

    /**
     * パーティクルの設定取得
     * @param asset_id
     * @param url
     * @returns
    */
    const getParticlesOptions = async (asset_id: number, url: string) => {
        const response = await axios.get(url);
        if (response) {
            // フルスクリーンを無効化
            response.data.fullScreen = { enable: false };
            // particlesOptionsをオブジェクトとして更新
            setParticlesOptions(particlesOptions => ({ ...particlesOptions, [asset_id]: response.data }));
        }
    }

    /**
     * アイテム選択処理
     * @param asset_id 
     */
    const ItemSelect = (asset_id: number) => {
        setSelectedAssetID(asset_id);
        //選択したアイテムをプレビューに表示
        const item = userAssetItems.filter(e => e.asset_id === asset_id);
        //プレビューの更新
        setUserLandPreview(prevState => ({
            ...prevState,
            // 条件に応じたプロパティの更新
            background: selectedAssetType === 1 ? item[0].asset_path : prevState.background,
            back_item: selectedAssetType === 2 ? item[0].asset_path : prevState.back_item,
            particle: selectedAssetType === 5 ? item[0].asset_path : prevState.particle,
            character: selectedAssetType === 7 ? item[0].asset_path : prevState.character,
            character_effect: selectedAssetType === 6 ? item[0].asset_path : prevState.character_effect,
        }));
    }

    /**
     * DraggableGridContainerで更新されたグリッド画像を反映
     * @param updatedGridImages 
     */
    interface GridImages {
        [key: string]: string;
    };
    const handleGridImagesUpdate = (updatedGridImages: GridImages) => {
        setUserLandPreview(prevState => ({
            ...prevState,
            // 条件に応じたプロパティの更新
            land_item: updatedGridImages
        }));
    };

    const [isLandSettinComfirm, setIsLandSettingComfirm] = useState<boolean>(false);
    /**
     * ランド設定確認処理
     */
    const LandSettingComfirm = () => {
        setIsLandSettingComfirm(true);
    }
    /**
    * ランド設定処理
    */
    const LandSetting = () => {
        const query = {
            student_land: userLandPreview
        };
        axios.post(common.addAccessToken(process.env.REACT_APP_API_STUDENT_LAND_POST), query)
            .then((response) => {
                setIsLandSettingSuccess(true);
                setIsLandSettingFailed(false);
                refreshUserData();
                //2秒後にモーダルを閉じる
                setTimeout(() => {
                    //メッセージを初期化
                    messegeInit();
                    setIsModalOpen(false);
                    setIsLandSettingComfirm(false);
                }, 2000);
            })
            .catch((error) => {
                setIsLandSettingSuccess(false);
                setIsLandSettingFailed(true);
                setIsLandSettingMessage(error.response.data.report);
            });
    }
    return (
        <>
            <PreviewArea>
                {
                    userLandPreview &&
                    <PreviewContainer land={userLandPreview}>
                        <SettingButton top={30} left={10} onClick={() => { SettingModal(1) }}><RubyText text="背景{はいけい}を変{か}える"></RubyText></SettingButton>
                        <SettingButton top={30} right={20} onClick={() => { SettingModal(2) }}><RubyText text="背景{はいけい}アイテムを変{か}える"></RubyText></SettingButton>
                        <SettingButton top={50} left={20} onClick={() => { SettingModal(5) }}><RubyText text="効果{こうか}を変{か}える"></RubyText></SettingButton>
                        <SettingButton top={50} left={55} onClick={() => { SettingModal(7) }}><RubyText text="キャラクターを変{か}える"></RubyText></SettingButton>
                        <SettingButton top={70} left={60} onClick={() => { SettingModal(6) }}><RubyText text="エフェクトを変{か}える"></RubyText></SettingButton>
                        <SettingButton bottom={5} left={30} onClick={() => { SettingModal(4) }}><RubyText text="床{ゆか}アイテムを置{お}く"></RubyText></SettingButton>
                    </PreviewContainer>
                }
            </PreviewArea>
            <SaveButton onClick={() => { LandSettingComfirm() }}></SaveButton>
            {isModalOpen && (
                <Modal >
                    <p><RubyText text="アイテムを選択{せんたく}してください"></RubyText></p>
                    {
                        selectedAssetType !== 4 && selectedAssetType !== 5 &&
                        <>
                            <ItemGridContainer>
                                {userAssetItems &&
                                    userAssetItems.map(e =>
                                        <ItemGrid key={e.asset_id} onClick={() => { ItemSelect(e.asset_id) }} className={selectedAssetID == e.asset_id ? 'active' : ''}>
                                            <ItemImg src={e.asset_path}></ItemImg>
                                        </ItemGrid>
                                    )}
                                {
                                    !userAssetItems &&
                                    <p>アイテムがありません</p>
                                }
                            </ItemGridContainer>
                        </>
                    }
                    {
                        selectedAssetType === 4 &&
                        <>
                            {!userAssetItems &&
                                <p>アイテムがありません</p>
                            }
                            <DraggableGridContainer userAssetItems={userAssetItems} updateGridImages={handleGridImagesUpdate}>

                            </DraggableGridContainer>
                        </>
                    }
                    {
                        selectedAssetType === 5 &&
                        <>
                            {
                                <ItemGridContainer>
                                    {userAssetItems &&
                                        userAssetItems.map(e =>
                                            <ItemParticle onClick={() => { ItemSelect(e.asset_id) }} className={selectedAssetID == e.asset_id ? 'active' : ''}>
                                                {
                                                    particlesOptions[e.asset_id] &&
                                                    <Particles id={`particles_${e.asset_id}`} key={e.asset_id} options={particlesOptions[e.asset_id]} />
                                                }
                                            </ItemParticle>
                                        )}
                                    {
                                        !userAssetItems &&
                                        <p>アイテムがありません</p>
                                    }
                                </ItemGridContainer>
                            }
                        </>
                    }
                    <ModalButtonArea>
                        <Button.Default className="gray" onClick={() => { setIsModalOpen(false) }}>戻る</Button.Default>
                        <Button.Default className="" onClick={() => { setIsModalOpen(false) }}>けってい</Button.Default>
                    </ModalButtonArea>
                </Modal>
            )}
            {
                isLandSettinComfirm && (
                    <Comfirm closeEvent={() => { setIsLandSettingComfirm(false) }} agreeEvent={() => { LandSetting() }}>
                        <p><RubyText text="ひろばの設定{せってい}を完了{かんりょう}しますか？"></RubyText></p>
                        {
                            //エラーメッセージがあれば表示
                            isLandSettingFailed &&
                            <Message.Error><RubyText text={isLandSettingMessage}></RubyText></Message.Error>
                        }
                        {
                            isLandSettingSuccess &&
                            <Message.Success><RubyText text="設定{せってい}が完了{かんりょう}しました"></RubyText></Message.Success>
                        }
                    </Comfirm>
                )
            }
        </ >
    )
}

export default LandSetting;