import React from "react";
import Particles from "react-tsparticles";
import axios from "axios";
import { useState, useEffect } from "react";
import { UseUserLand } from "../../../providers/UserProvider.tsx";

export default function ParticleContainer() {

  const userLand = UseUserLand();
  const particleURL = userLand.particle;

  const [particlesOptions, setParticlesOptions] = useState(null);
  const [particlesExist, setParticlesExist] = useState(false);
  useEffect(() => {
    axios.get(particleURL)
      .then((response) => {
        setParticlesOptions(response.data);
        setParticlesExist(true);
      })
      .catch((error) => {
        console.log(error);
        setParticlesExist(false);
      });
  }, []);

  return (
    <>
    {
      particlesExist && (
        <Particles options={particlesOptions} />
      )
    }
    </>
  );
};