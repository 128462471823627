import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { UseUser } from "../providers/UserProvider.tsx";

const Button = styled.div`
    background: url(./img/button-back.png);
    background-size: contain;
    background-repeat: no-repeat;
    z-index:99;
    height: 10%;
    width: 20%;
    right: 0;
    top: 5%;
    position: absolute;
`;
const ButtomButton = styled.div<{src:string}>`
    background: url(${props => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    z-index:99;
    height: 15%;
    width: 20%;
    right: 0;
    bottom: 2%;
    position: absolute;
`;
export default function HomeButton() {

    const navigate = useNavigate();
    const location = useLocation();
    
    const [requirePasswordChange, setRequirePasswordChange] = useState<boolean>(false);
    const user = UseUser();
    
    useEffect(() => {
        if (user?.is_required_setting) {
            //ユーザー情報が未設定の場合は、パスワード変更が必須
            setRequirePasswordChange(true);
        }
    }, [user]);


    // パスワード変更が必須の場合は、ホームボタンを表示しない
    if (requirePasswordChange && location.pathname !== '/user_setting') {
        return <></>;
    }
    if (location.pathname === '/home' || location.pathname === '/login') {
        return <></>;
    }
    //ショップ画面の時は、広場設定ボタンを追加する
    if (location.pathname === '/shop') {
        return (
            <>
                <Button onClick={() => navigate('/home')} />
                <ButtomButton src="./img/btn-side__L3.png" onClick={() => navigate('/land-setting')} />
            </>
        )
    }
    //広場設定画面の時は、ショップボタンを追加する
    if (location.pathname === '/land-setting') {
        return (
            <>
                <Button onClick={() => navigate('/home')} />
                <ButtomButton src="./img/btn-side__L2.png" onClick={() => navigate('/shop')} />
            </>
        )
    }

    return (
        <Button onClick={() => navigate('/home')} />
    )
}