import React from "react";
import Message from "../../../components/Message.tsx";
import RubyText from "../../../components/RubyText.tsx";

export const ErrorMessage = (props: { message: string }) => {

    return (
        props.message === '' ?
            <></> :
            <Message.Error><RubyText text={props.message} /></Message.Error>
    )
}

export default ErrorMessage;