import React, { useState, useRef } from 'react';

function Accordion({ title, children }) {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <details open={isOpen} onToggle={toggleAccordion}>
            <summary>{title}</summary>
            <div
                ref={contentRef}
                style={{
                    maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.4s ease'
                }}
            >
                {children}
            </div>
        </details>
    );
}

export default Accordion;
