import React, { createContext, useState, useEffect , useContext} from 'react';
import axios from 'axios';
import common from '../common/common.ts';
import Loading from '../components/Loading.tsx';

// Contextの作成
export const CodeContext = createContext(null);

export const UseCode = () => useContext(CodeContext);

// Contextプロバイダコンポーネント
export const CodeProvider = ({ children }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        // APIリクエストの実行
        const fetchData = async () => {
            try {
                const response = await axios.get(common.addAccessToken(process.env.REACT_APP_API_CODE_GET));
                setData(response.data.data);
            } catch (error) {
                console.error("APIからデータを取得できませんでした:", error);
            }
        };

        fetchData();
    }, []);

    if(data === null){
        //return <Loading isLoading={true} />;
    }
    return (
        <CodeContext.Provider value={data}>
            {children}
        </CodeContext.Provider>
    );
};
