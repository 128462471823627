import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import RubyText from "../../../components/RubyText.tsx";
import Modal from "../../../components/Modal.tsx";
import Button from "../../../components/Button.tsx";
import axios from "axios";
import { useState } from "react";
import common from '../../../common/common.ts';
import Message from "../../../components/Message.tsx";
import { UseCode } from "../../../providers/CodeProvider.tsx";
import { UseUser, UseUserRefresher } from "../../../providers/UserProvider.tsx";
import { co } from "@fullcalendar/core/internal-common";


const RecordTabArea = styled.div`
    display: flex;
    width: 90%;
    margin-top: 1em;
    border-radius: 10px 10px 0 0;
    & > :first-child{
        /* border-radius: 1em 0 0 0; */
        border-radius: 10px 0 0 0;
    }
    & > :last-child{
        border-radius: 0 10px 0 0;
    }
`;

const RecordTab = styled.div`
    width: 25%;
    height: 2em;
    line-height: 2em;
    font-size: 1em;
    background-color: #83f28e;
    border:1px dotted #69da00;
    &.active{
        background-color: #ff9d34;
    }
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
`;

const RecordArea = styled.div`
    width: 90%;
    height: 90%;
    background-color:transparent;
    border:1px dotted #69da00;
    border-top: none;
    overflow-y: scroll;
`;

const GradeHeader = styled.div`
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 1em;
    h1{
        font-size: 2em;
        margin-left: 0.5em;
        @media (max-width: 900px) {
            font-size: 1em;
        }
    }
    @media (max-width: 900px) {
        margin-top: 0.5em;
    }
`;

const GradeSwitcher = styled.div`
    width: 3em;
    height: 3em;    
    &.prev{
        background-image: url('./img/button-prev.png');
        background-size: 100%;
        background-repeat: no-repeat;
        
    }
    &.next{
        background-image: url('./img/button-next.png');
        background-size: contain;
        background-repeat: no-repeat;
        
    }
    &.disabled{
        //background-imageをグレーアウトにする
        filter: grayscale(100%);
    }
    @media (max-width: 900px) {
        width: 2em;
        height: 2em; 
    }
`;

const CompetitionResult = styled.div`
    width: 90%;
    margin-top: 1em;
`;
const CompetitionHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #28c726;
    height: 3em;
    border: 2px solid #000000;
    margin-top: 1em;
    h2{
        margin-left: 0.5em;
        text-align:left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media (max-width: 900px) {
            font-size: 1em;
        }
    }
    button{
        min-width:fit-content;
        padding-left: 1em !important;
        padding-right: 1em !important;
    }
    @media (max-width: 900px) {
        margin-top: 0.5em;
    }
`;

const CompetitionBody = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    padding: 0;
    border-right: 2px solid #000000;
    border-left: 2px solid #000000;
    border-bottom: 2px solid #000000;
    th, td {
        padding: 0;
        border: 1px solid #ccc;
        text-align: center;
        vertical-align: middle;
        width: 12.5%;
        background-color: #fff;
        height: 2.5em;
        @media (max-width: 900px) {
            font-size: 0.5em;
        }
    }
    th {
        background-color: #eee;
    }
    .headline {
        background-color: #fffb95;
    }
    .red{
            background-color: #ff959c;
    }
`;
const UserCourseRecord = () => {
    const [selectedCourseName, setSelecterCourseName] = useState('ranking');
    const selectCourse = (courseName: string) => {
        setSelecterCourseName(courseName);
    };
    const [courseRecords, setCourseRecords] = useState([]);
    const [displayRecords, setDisplayRecords] = useState([]);
    const [displayGrade, setDisplayGrade] = useState(0);
    //コードの取得
    const codes = UseCode();
    //ユーザー情報の取得
    const user = UseUser();

    useEffect(() => {
        const getCourseRecord = async () => {
            // 記録情報をリセット
            setCourseRecords([]);
            setDisplayRecords([]);
            // APIからデータを取得
            const response = await axios.get(common.addAccessToken(process.env.REACT_APP_API_RECORD_COURSE_GET));
            if (response) {
                // courseRecordsを更新
                setCourseRecords(response.data.data);
            }
        };

        // コンポーネントがマウントされた時に記録情報を取得
        getCourseRecord();
    }, []);

    // courseRecordsが更新されたら、displayRecordsを設定
    useEffect(() => {
        // courseRecordsが更新された後に、setDisplayRecordsを呼び出す
        // ここで必要なロジックを適用して、適切なgradeを選択
        if (Object.keys(courseRecords).length > 0) {
            setDisplayGrade(user.grade_id);
            setDisplayRecords(courseRecords[user.grade_id]);
        }
    }, [courseRecords]);

    const getRecords = async (grade) => {
        if (displayGrade + grade <= 0 || displayGrade + grade > user.grade_id) {
            return false;
        }
        setDisplayGrade(displayGrade + grade);
        setDisplayRecords(courseRecords[displayGrade + grade]);
    }

    return (
        <>
            <RecordTabArea>
                <RecordTab className={selectedCourseName == 'ranking' ? 'active' : ''} onClick={() => selectCourse('ranking')}>ランキング</RecordTab>
                <RecordTab className={selectedCourseName == 'enjoy' ? 'active' : ''} onClick={() => selectCourse('enjoy')}>エンジョイ</RecordTab>
                <RecordTab className={selectedCourseName == 'mypace' ? 'active' : ''} onClick={() => selectCourse('mypace')}>マイペース</RecordTab>
                <RecordTab className={selectedCourseName == 'hapihapi' ? 'active' : ''} onClick={() => selectCourse('hapihapi')}>はぴはぴ</RecordTab>
            </RecordTabArea>
            <RecordArea>
                {
                    displayGrade == 0 &&
                    <p>読み込み中...</p>
                }
                {
                    displayGrade > 0 &&
                    <>
                        <GradeHeader>
                            <GradeSwitcher onClick={() => { getRecords(-1) }} className={displayGrade <= 1 ? 'prev disabled' : 'prev'}></GradeSwitcher>
                            <h1>{displayGrade}年生</h1>
                            <GradeSwitcher onClick={() => { getRecords(1) }} className={displayGrade >= user.grade_id ? 'next disabled' : 'next'}></GradeSwitcher>
                        </GradeHeader>

                        <CompetitionResult>
                            {
                                !displayRecords &&
                                <p>読み込み中...</p>
                            }
                            {
                                displayRecords && displayRecords[selectedCourseName] && Object.entries(displayRecords[selectedCourseName]).map(([key, value], index) => {
                                    return (
                                        <>
                                            <CompetitionHeader key={index}>
                                                <h2><RubyText text={key}></RubyText></h2>
                                                <Button.Default className="warning small"><RubyText text="この種目{しゅもく}の説明{せつめい}"></RubyText></Button.Default>
                                            </CompetitionHeader>
                                            <CompetitionBody>
                                                <tr>
                                                    <td className="headline"><RubyText text="記録回数{きろくかいすう}"></RubyText></td>
                                                    <td>{value['count']}</td>
                                                    <td className="headline"><RubyText text="合計回数{ごうけいかいすう}"></RubyText></td>
                                                    <td>{value['sum']}</td>
                                                    <td className="headline"><RubyText text="平均回数{へいきんかいすう}"></RubyText></td>
                                                    <td>{value['avg']}</td>
                                                    <td className="headline"><RubyText text="最大回数{さいだいかいすう}"></RubyText></td>
                                                    <td>{value['max']}</td>
                                                </tr>
                                                {
                                                    selectedCourseName == 'ranking' ?
                                                        <tr>
                                                            <td className="headline red"><RubyText text="県内順位{けんないじゅんい}"></RubyText></td>
                                                            <td>{value['rank']}</td>
                                                            <td className="headline red"><RubyText text="県内平均{けんないへいきん}"></RubyText></td>
                                                            <td>{value['pref_avg']}</td>
                                                            <td className="headline red"></td>
                                                            <td></td>
                                                            <td className="headline red"></td>
                                                            <td></td>
                                                        </tr> : <></>

                                                }
                                                {
                                                    selectedCourseName == 'enjoy' ?
                                                        <tr>
                                                            <td className="headline red"><RubyText text="県内平均{けんないへいきん}"></RubyText></td>
                                                            <td>{value['pref_avg']}</td>
                                                            <td className="headline red"><RubyText text=""></RubyText></td>
                                                            <td></td>
                                                            <td className="headline red"></td>
                                                            <td></td>
                                                            <td className="headline red"></td>
                                                            <td></td>
                                                        </tr> : <></>
                                                }
                                                {
                                                    selectedCourseName == 'mypace' ?
                                                        <tr>
                                                            <td className="headline red"><RubyText text="目標{もくひょう}"></RubyText></td>
                                                            <td>{value['ambition_score']}</td>
                                                            <td className="headline red"><RubyText text=""></RubyText></td>
                                                            <td></td>
                                                            <td className="headline red"></td>
                                                            <td></td>
                                                            <td className="headline red"></td>
                                                            <td></td>
                                                        </tr> : <></>
                                                }
                                                {

                                                    selectedCourseName == 'hapihapi' ?
                                                        <tr>
                                                            <td className="headline red"><RubyText text=""></RubyText></td>
                                                            <td></td>
                                                            <td className="headline red"><RubyText text=""></RubyText></td>
                                                            <td></td>
                                                            <td className="headline red"></td>
                                                            <td></td>
                                                            <td className="headline red"></td>
                                                            <td></td>
                                                        </tr> : <></>
                                                }
                                            </CompetitionBody>
                                        </>
                                    );
                                })
                            }
                        </CompetitionResult>
                    </>
                }
            </RecordArea>
        </>
    );
}
export default UserCourseRecord;