import React from "react";
import styled from "styled-components";
import RubyText from "../../../components/RubyText.tsx";
import { useState } from "react";
import Modal from "../../../components/Modal.tsx";
import Button from "../../../components/Button.tsx";
import axios from "axios";
import { useEffect } from "react";
import common from "../../../common/common.ts";
import Comfirm from "../../../components/Comfirm.tsx";

const Newses = styled.div`
    max-width: 100%;
    padding:2px 20px 2px 20px;
    background: #7bf37fce;
    color:#000;
    border-radius: 10px;
    border: 3px solid #fff;
    font-weight: bold;
    font-size: 0.5rem;
    margin-top: 10px;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
`;

const NewsHeader = styled.p`
    margin-top: 10px;  
    font-weight: bolder;
    font-size: 1em;

`;
const NewsTitle = styled.p`
    
    font-weight: bolder;
    font-size: 1.3em;
    //detailクラスが付与されている場合は、文字を赤くする
    &.detail{
        font-size:x-large;
        margin: 1em;  
        padding: 10px;
        border-bottom: 2px solid #000;
        text-align: left;
    }
    @media (max-width: 900px) {
    font-size: 1em;
    }
`;

const NewsBody = styled.p`
    margin: 0;  
    &.detail{
        margin: 1em;  
        margin-top: 10px;
        background-color: #fff6cc;
        border: 2px solid #000;
        border-radius: 10px;
        padding: 10px;
        height: 40vh;
        text-align: left;
    }
    @media (max-width: 900px) {
    font-size: 0.8em;
    
    }
`;
const LoagingSpinner = styled.div`
    margin-left: 10px;  
    text-align: left;
    &::before{
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 3px solid #fff;
        border-top-color: #5ac700;
        animation: spin 1s linear infinite;
        margin: 10px 10px;
    }
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;
const ModalFooter = styled.div`
    position: sticky;
    bottom: -1px;
    background-color: #fff;
    width: 95%;
    margin: 0 auto;
`;
// ランドグリッドコンテナ
export const NewsContainer = () => {
    //axiosでお知らせ一覧を取得する
    const [newses, setNewses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get(common.addAccessToken(process.env.REACT_APP_API_NEWS_GET))
            .then((res) => {
                setNewses(res.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                //console.log(error.request.response);
                setIsLoading(false);
            });
    }, []);

    interface News {
        id: number,
        title: string,
        body: string,
    }

    const [selectedNews, setSelectedNews] = useState<News | undefined>(undefined);


    // モーダルの表示状態を管理するための状態変数
    const [isModalOpen, setIsModalOpen] = useState(false);

    // モーダルを開く関数
    const NewsDetail = (news_id: number) => {
        setSelectedNews(newses.find((news) => news.news_id === news_id));
        setIsModalOpen(true);
    }

    return (
        <>
            <NewsHeader><RubyText text="お知{し}らせ一覧{いちらん}" /></NewsHeader>
            {
                isLoading &&
                <LoagingSpinner></LoagingSpinner>
            }
            {newses.length == 0 &&
                <Newses>
                    <NewsTitle><RubyText text="お知{し}らせはありません"></RubyText></NewsTitle>
                </Newses>
            }
            {
                newses.map((news, index) => {
                    return (
                        <Newses key={index} onClick={() => { NewsDetail(news.news_id) }}>
                            <NewsTitle><RubyText text={news.title}></RubyText></NewsTitle>
                            <NewsBody><RubyText text={news.body}></RubyText></NewsBody>
                        </Newses>
                    )
                })
            }
            {isModalOpen && (
                <Modal >
                    <h1><RubyText text="お知{し}らせ詳細{しょうさい}"></RubyText></h1>
                    <NewsTitle className="detail"><RubyText text={selectedNews?.title}></RubyText></NewsTitle>
                    <NewsBody className="detail"><RubyText text={selectedNews?.body}></RubyText></NewsBody>
                    <ModalFooter>
                    <Button.Default className="modal-close" onClick={()=>{setIsModalOpen(false)}}>とじる</Button.Default>
                    </ModalFooter>
                </Modal>
            )}
            
        </>
    );
}
