import React from "react";
import styled from "styled-components";
import { UseUser } from "../providers/UserProvider.tsx";

const TitleCard = styled.div`
    min-width:300px;
    width: fit-content;
    padding:2px 20px 2px 20px;
    background: #7bf37fce;
    color:#000;
    border-radius: 50px;
    border: 2px solid #fff;
    font-weight: bold;
    font-size: 0.6rem;
`;

const UserTitle = styled.div`
    margin: 0;  
    margin-right: 5px;
    display: inline-flex;
    font-size: 0.8rem;
`;

const Container = styled.div`
    width: 100%;
    margin-top: 10px;
    height: fit-content;
`
export default function Profile() {
    const user = UseUser();
    //ニックネームを表示、userがnullの場合は未登録ユーザと表示
    return (
        <Container>
            <TitleCard>
                <UserTitle>{user && user.nickname }</UserTitle>さん
            </TitleCard>
        </Container>
    )
}