import React, { useEffect } from "react";
import styled from "styled-components";
import { ConfirmButton } from "../../components/ConfirmButton.tsx";
import { useNavigate, useLocation } from "react-router-dom";
import RubyText from "../../components/RubyText.tsx";
import Character from "../../components/Character.tsx";
import Modal from "../../components/Modal.tsx";
import Button from "../../components/Button.tsx";
import axios from "axios";
import { useState } from "react";
import common from '../../common/common.ts';
import { Asset } from '../../models/Asset.ts';
import Comfirm from "../../components/Comfirm.tsx";
import Message from "../../components/Message.tsx";
import { UseCode } from "../../providers/CodeProvider.tsx";
import { UseUser , UseUserRefresher } from "../../providers/UserProvider.tsx";
import UserRecord from "./components/UserRecord.tsx";
import UserCourseRecord from "./components/UserCourseRecord.tsx";
import Calendar from "./components/Calendar.tsx";
import Ranking from "./components/Ranking.tsx";



const Content = styled.main`
    display: flex;
    height:85%;
    margin-top:6em;
    @media (max-width: 900px) {
        margin-top:2em;
    }
`;

const FlexDiv = styled.div`
    height: 100%;
    overflow: overlay;
    &.left{
        width: 30%;
        height:90%;
    }
    &.right{
        width: 65%;
    }
`;

const RecordTabArea = styled.div`
    display: flex;
    margin-top: 1em;
    & > :first-child{
        margin-left: 1em;
    }
`;

const RecordTab = styled.div`
    width: 20%;
    height: 3em;
    background-color: #fff;
    //上辺だけ丸くする
    border-radius: 1em 1em 0 0;
    border:3px solid #fff;
    border-bottom: none;
    background-color: #7bf37fce;
    line-height: 3em;
    font-size: 1em;
    margin-right: 1em;
    color: #fff;
    &.active{
        background-color:#ffffffc5;
        color: #000;
    }
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
`;

const RecordContent = styled.div`
    width: 99%;
    height: 80%;
    background-color: #ffffffc5;
    border-radius: 1em;
    border:3px solid #fff;
    padding-top: 10px;
    overflow: scroll;
`;

export const Record = () => {
    const location = useLocation();
    const urlParams = location.state as { initialTab: string , addStamp: boolean};
    if(urlParams.initialTab){
        
    }
    const [selectedTabNo, setSelectedTabNo] = useState<String>(urlParams.initialTab);
    const selectTab = (tabName: String) => {
        setSelectedTabNo(tabName);
    };
    return (
        <>
        <Content>
            <FlexDiv className="left">
                <Character />
            </FlexDiv>
            <FlexDiv className="right">
                <RecordTabArea>
                    <RecordTab className={selectedTabNo == 'personal' ? 'active':''} onClick={()=>{selectTab('personal')}}><RubyText text="個人の記録"></RubyText></RecordTab>
                    <RecordTab className={selectedTabNo == 'record' ? 'active':''} onClick={()=>{selectTab('record')}}><RubyText text="記録をみる"></RubyText></RecordTab>
                    <RecordTab className={selectedTabNo == 'ranking' ? 'active':''} onClick={()=>{selectTab('ranking')}}><RubyText text="ランキングをみる"></RubyText></RecordTab>
                    <RecordTab className={selectedTabNo == 'calendar' ? 'active':''} onClick={()=>{selectTab('calendar')}}><RubyText text="運動カレンダー"></RubyText></RecordTab>                
                </RecordTabArea>
                <RecordContent>
                    {
                        selectedTabNo == 'personal' ? <UserRecord/> : 
                        selectedTabNo == 'record' ? <UserCourseRecord/> : 
                        selectedTabNo == 'ranking' ? <Ranking/> : 
                        selectedTabNo == 'calendar' ? <Calendar/> : <></>
                    }


                </RecordContent>
            </FlexDiv>
        </Content>
        </>
    );
}

export default Record;