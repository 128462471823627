import styled from "styled-components";

export const RightContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 25%;
    height: 100vh;
    z-index: 1;
    text-align:-webkit-right;
`;