import styled from "styled-components";

export const InputArea = styled.div`
    background-color: #7bf37fb7;
    width: 50vw;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 5px solid #fff;
    border-radius: 5px;
    max-height: 400px;
    min-height: 250px;
    @media (max-width: 900px) {
    width: 80vw; /* スマホサイズの場合の幅 */
    min-height: inherit;
    padding: 3px;
    }
`;