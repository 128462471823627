import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"
import styled from "styled-components";
import Button from "./Button.tsx";

const ModalDiv = styled.div`
    height:30vh;
    width:50vw;
    text-align: -webkit-center;
    position: fixed;
    //中央寄せ
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 10px;
    border: 3px solid #000000;
    padding: 10px;
    overflow: scroll;
    z-index: 9999;
    //フェードインアニメーション
    animation: fadein 0.5s;
    //フェードインアニメーション
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    h3{
        margin: 0;
    }
`;
const ButtonArea = styled.div`
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 10px;
    margin: 0 auto;
    width: 100%;
`;
/**
 * モーダルコンポーネント
 * @returns 
 */
export default function Comfirm(props: { children: React.ReactNode , closeEvent:()=> void , agreeEvent: () => void}) {
    // 背景画像の設定
    return (
        <ModalDiv>
            <h3>確認</h3>
            {props.children}
            <ButtonArea>
                <Button.Default className="gray" onClick={props.closeEvent}>いいえ</Button.Default>
                <Button.Default onClick={props.agreeEvent}>はい</Button.Default>            
            </ButtonArea>
        </ModalDiv>
    );
}
