import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button.tsx";
import Character from "../../components/Character.tsx";
import { ConfirmButton } from "../../components/ConfirmButton.tsx";
import RubyText from "../../components/RubyText.tsx";
import InputText from "../../components/InputText.tsx";
import { Competition } from "../../models/Competition.ts";
import Modal from "../../components/Modal.tsx";
import axios from "axios";
import List from "./components/List.tsx";
import { useNavigate } from "react-router";
import ErrorMessage from "./components/ErrorMessage.tsx";
import { UseUserRefresher } from "../../providers/UserProvider.tsx";

const Container = styled.div`
    display: flex;
    margin: 50px 50px 10px 50px;
    height: 50vh;
    width: 90%;
    @media (max-width: 900px) {
        margin: 10px 50px 10px 50px;
    }
`;

const CharacterArea = styled.div`
    width:25%;
`;

const CompetitionArea = styled.main`
    gap: 100px;
    width:50%;
    background-color: rgba(255,255,255,0.7);
    border-radius:10px;
    border: 3px solid white;
    padding:20px;
    box-sizing: border-box;
    text-align: left;
    @media (max-width: 900px) {
        width: 70%;
        overflow:scroll;
    }
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
    > div.flex{
        display: flex;
        align-items: center;
    }
`;

const CompetitionDescription = styled.div`
    background: white;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-top: 10px;
    & > div:not(:last-child){
        border-bottom: 1px dashed black;
    }
    
`;

const MyInput = styled(InputText)`
    display: inline-flex;
    text-align: right;
    &&& {
        margin-right: 10px;
        text-align: left;
    }
    //２番目の要素に対してのみ適用
    &&& > span{
        min-width:50px;
    }
    &&& > div{
        width: 6em;
    }
`;

const MyInputTime = styled(InputText)`
    display: inline-flex;
    text-align: right;
    &&& {
        width: 4em;
        margin-right: 10px;
        text-align: left;
        
    }
`;

const MyInputTimeDiv = styled.div`
    margin-right: 5px;
    display: flex;
    align-items: end;
    &&& {
        && > div > div:first-child {
            width:4em;
        }
    }
`;

const VideoFrame = styled.iframe`
    width: 90%;
    height: 80%;
    margin: auto;
    margin-top: 10px;
    display: block;
`;

const Menu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    //div要素を横並びにする
    & > div.left{
        width:55%;
        text-align: -webkit-left;
    }
    & > div.right{
        width:45%;
        text-align: -webkit-right;
    }
`;

const getAmbitionScore = (competition: Competition): Promise<number> => {

    //envファイルからAPIのURLを取得
    let apiUrl = process.env.REACT_APP_API_URL || '';

    //APIから目標値取得
    return axios.get(`${apiUrl}/ambition/${sessionStorage.getItem('accessToken')}?competition_id=${competition.competition_id}`)
        .then((res) => {

            //目標値が存在するか
            const hasAmbitiousScore = Object.keys(res.data.data).indexOf('ambitions_score') > 0;

            if (hasAmbitiousScore) {
                return res.data.data['ambitions_score'];
            } else {
                return -1;
            }
        })
        .catch(error => {
            console.log(error.request.response);

            return -1;
        });
}

function timeToMinutes(hours: number, minutes: number, seconds: number): number {
    // 時間を分に変換
    const totalMinutes = hours * 60 + minutes + seconds / 60;
    // 四捨五入してDecimal(5,2)相当の精度で返す
    return Math.round(totalMinutes * 100) / 100;
}

function minutesToTime(minutes: number): { hours: number, minutes: number, seconds: number } {
    // 分を時と分に分割
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    // 残りの分を秒に変換
    const seconds = Math.round((minutes - hours * 60 - mins) * 60);
    return { hours, minutes: mins, seconds };
}




export const InputRecord = (props: { OnSetEvent: () => void, Competition: Competition }) => {

    const navigate = useNavigate();
    //ユーザー情報の更新
    const refreshUserData = UseUserRefresher();


    // マイペースコースの運動データの場合は、目標値を取得する。
    const [ambition, setAmbition] = useState<number>(-1);
    useEffect(() => {
        if (props.Competition.course_id === 3) {
            getAmbitionScore(props.Competition)
                .then((score: number) => setAmbition(score));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ユーザーが入力した運動データ
    const [record, setRecord] = useState<string>('');
    function handleInputChange(value: string) {
        setRecord(value);
    }

    const [time, setTime] = useState<{
        Hours: string,
        Minutes: string,
        Seconds: string,
    }>({
        Hours: "",
        Minutes: "",
        Seconds: ""
    });
    function handleTimeInputChange(type: string, value: string) {

        let newValue = {
            Hours: time.Hours,
            Minutes: time.Minutes,
            Seconds: time.Seconds
        };

        switch (type) {
            case "Hours":
                newValue.Hours = value;
                break;
            case "Minutes":
                newValue.Minutes = value;
                break;
            case "Seconds":
                newValue.Seconds = value;
                break;
        }

        setTime(newValue);

        let convertedValue = timeToMinutes(parseInt(newValue.Hours) || 0,
            parseInt(newValue.Minutes) || 0,
            parseInt(newValue.Seconds) || 0);

        setRecord(String(convertedValue));

    }

    const [showModal, setShowModal] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>('');

    // 決定ボタンクリックイベント
    const onClickConfirm = async () => {

        //エラー内容のクリア
        setErrorMessage('');

        //必須チェック
        if (record === '') {
            setErrorMessage('運動{うんどう}データを入力{にゅうりょく}してください');
            return;
        }

        if (Number.parseFloat(record) === 0) {
            setErrorMessage('運動{うんどう}データは0以外{いがい}を入力{にゅうりょく}してください');
            return;
        }

        // 更新する目標内容
        const query = {
            course_id: props.Competition.course_id,
            competition_id: props.Competition.competition_id,
            record_date: new Date().toLocaleDateString("ja-jp", { year: "numeric", month: "2-digit", day: "2-digit" }).replaceAll('/', '-'),
            score: record
        };

        let apiUrl = process.env.REACT_APP_API_URL || '';

        // 入力された記録を登録
        await axios.post(`${apiUrl}/record/register/${sessionStorage.getItem('accessToken')}`, query)
            .then((response) => {

                const params: {
                    initialTab: string,
                    addStamp: boolean,
                } = {
                    initialTab: 'calendar',
                    addStamp: true
                }
                //ユーザ情報の再取得
                refreshUserData();
                // カレンダー画面へ遷移
                navigate(`/record`, { state: params });
            })
            .catch((err: {
                response: {
                    data: {
                        result: number,
                        message: string,
                        report: string,
                    }
                }
            }) => {
                setErrorMessage(err.response.data.report);
            });
    }

    return (
        <>
            <h1><RubyText text="運動{うんどう}データを入力{にゅうりょく}する" /></h1>
            <Container>
                <CharacterArea>
                    <Character />
                </CharacterArea>
                <CompetitionArea>
                    <div className="flex">
                        <RubyText text="運動{うんどう}データを入力{にゅうりょく}してください。" />
                        <Button.Default className="small" style={{ marginLeft: "auto" }} onClick={() => setShowModal(true)}><RubyText text={"ムービーを見{み}る"} /></Button.Default>
                    </div>

                    <CompetitionDescription>
                        <List.Component title="コース選択{せんたく}" value={props.Competition.course_name} />
                        <List.Component title="種目{しゅもく}" value={props.Competition.competition_name} />
                    </CompetitionDescription>

                    {
                        showModal &&
                        <Modal>
                            <VideoFrame src={props.Competition.link} />
                            <Button.Default onClick={() => setShowModal(false)}>とじる</Button.Default>
                        </Modal>
                    }

                    {
                        props.Competition.course_id === 3 &&
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "15px"
                        }}>
                            {
                                props.Competition.score_unit === 4 ?
                                    <>
                                        <div style={{ width: "100%", display: "inline-flex" }}>
                                            <label><RubyText text={"目標{もくひょう}"} /></label>
                                            <label style={{ margin: "0 10px 0 auto", display: 'block', textAlign: 'right' }}>
                                                <RubyText text={`${minutesToTime(ambition).hours}時間{じかん}${minutesToTime(ambition).minutes}分{ふん}${minutesToTime(ambition).seconds}秒{びょう}`} />
                                            </label>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div style={{ width: "calc(15em + 10px)", display: "inline-flex" }}>
                                            <label><RubyText text={"目標{もくひょう}"} /></label>
                                            <label style={{ margin: "0 10px 0 auto", width: `${String(ambition).length}em`, display: 'block', textAlign: 'right' }}>{ambition}</label>
                                        </div>
                                        <label>{props.Competition.score_unit_name}</label>
                                    </>
                            }

                            <Button.Default className="warning small"
                                style={{ display: "inline", marginLeft: "auto" }}
                                onClick={props.OnSetEvent}>
                                <RubyText text={"目標値{もくひょうち}修正{しゅうせい}"} />
                            </Button.Default>
                        </div>


                    }

                    {
                        props.Competition.score_unit === 4 ?
                            <>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <label style={{ width: "10em" }}><RubyText text={"記録{きろく}"} /></label>
                                    <MyInputTimeDiv>
                                        <MyInputTime
                                            OnChangeText={(value) => handleTimeInputChange("Hours", value)}
                                            Name='RecordHour'
                                            Value={time.Hours}
                                            Type='integer'
                                            useCustomKeyboard={true}
                                        />
                                        <span><RubyText text={"時間{じかん}"} /></span>
                                    </MyInputTimeDiv>
                                    <MyInputTimeDiv>
                                        <MyInputTime
                                            OnChangeText={(value) => handleTimeInputChange("Minutes", value)}
                                            Name='RecordMinute'
                                            Value={time.Minutes}
                                            Type='integer'
                                            useCustomKeyboard={true}
                                        />
                                        <span><RubyText text={"分{ふん}"} /></span>
                                    </MyInputTimeDiv>
                                    <MyInputTimeDiv>
                                        <MyInputTime
                                            OnChangeText={(value) => handleTimeInputChange("Seconds", value)}
                                            Name='RecordSecond'
                                            Value={time.Seconds}
                                            Type='integer'
                                            useCustomKeyboard={true}
                                        />
                                        <span><RubyText text={"秒{びょう}"} /></span>
                                    </MyInputTimeDiv>
                                </div>
                            </>
                            :
                            <div style={{ marginTop: "0px" }}>
                                <MyInput
                                    Label='記録{きろく}'
                                    OnChangeText={(value) => handleInputChange(value)}
                                    Name='StudentID'
                                    Value={record}
                                    Type={props.Competition.score_value_type}
                                    useCustomKeyboard={true}
                                />
                                <span><RubyText text={props.Competition.score_unit_name} /></span>
                            </div>
                    }

                    <ErrorMessage message={errorMessage} />
                </CompetitionArea>
            </Container >
            <Menu>
                <div className="right"><Button.Back /></div>
                <div className="left"><ConfirmButton onClick={onClickConfirm} /></div>
            </Menu>
        </>
    )

}

export default InputRecord;