import React, { useEffect } from "react";
import styled from "styled-components";
import { UseUserLand } from "../providers/UserProvider.tsx";
import { useState } from "react";

const CharacterEffect = styled.div<{ className: string, $img: string }>`
    width: 100%;
    height:100%;
    margin: auto;
    background: url(${props => props.$img});
    background-repeat:  no-repeat;
    background-size: cover;
    background-position: center;
`;

const CharacterImage = styled.div<{ $img: string }>`
    background: url(${props => props.$img});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    height:60vh;
`;

export default function Character() {

    const userLand = UseUserLand();

    const [character , setCharacter] = useState('');
    const [characterEffect , setCharacterEffect] = useState('');
    useEffect(() => {
        setCharacter(userLand.character);
        setCharacterEffect(userLand.character_effect);
    }, []);

    return (
        <CharacterEffect className="CharacterEffect" $img={characterEffect}>
            <CharacterImage $img={character} />
        </CharacterEffect>
    );
}