import styled from "styled-components";

export const ConfirmButton = styled.div`
    background: url(/img/button-decision.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 5rem;
    width: 50%;
    margin-top: 10px;
`;