import React from "react";
import { useNavigate } from "react-router-dom";
import ParticleContainer from "./components/ParticleContainer.tsx";
import { LandGridContainer } from "./components/LandGridContainer.tsx";
import { LandContainer } from "./components/LandContainer.tsx";
import { LeftContainer } from "./components/LeftContainer.tsx";
import { RightContainer } from "./components/RightContainer/RightContainer.tsx";
import { Button as RightButton } from "./components/RightContainer/Button.tsx";
import { HeaderContainer } from "./components/HeaderContainer/HeaderContainer.tsx";
import FooterContainer  from "./components/FooterContainer/FooterContainer.tsx";
import Profile from "../../components/Profile.tsx";
import Button from "../../components/Button.tsx";
import { NewsContainer } from "./components/NewsContainer.tsx";
import Comfirm from "../../components/Comfirm.tsx";


export default function Home() {

    const navigate = useNavigate();
    const moveView = (path: string , params:{}) => {
        navigate(path,{ state: params });
    }
    

    const [isLogoutModalOpen, setIsLogoutModalOpen] = React.useState(false);
    const logoutComfirm = () => {
        setIsLogoutModalOpen(true);
    }

    const logout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    }



    return (
        <>
            <div className="ObjectContainer">
                <ParticleContainer />
                <LandContainer className='LandContainer'>
                    <LandGridContainer />
                </LandContainer>
            </div>

            <HeaderContainer>
                <Profile />
            </HeaderContainer>

            <LeftContainer>
                <NewsContainer></NewsContainer>
            </LeftContainer>

            <RightContainer>
                <Button.Default className="danger" onClick={() => logoutComfirm()}>ログアウト</Button.Default>
                <RightButton $img="./img/btn-side__L4.png" onClick={() => moveView("/user-setting",{})}></RightButton>
                <RightButton $img="./img/btn-side__L2.png" onClick={() => moveView("/shop",{})}></RightButton>
                <RightButton $img="./img/btn-side__L3.png" onClick={() => moveView("/land-setting",{})}></RightButton>
            </RightContainer>

            <FooterContainer></FooterContainer>

            {isLogoutModalOpen && (
                <Comfirm closeEvent={() => { setIsLogoutModalOpen(false) }} agreeEvent={() => { logout() }}>
                    <p>ログアウトしますか？</p>
                </Comfirm>
            )}
        </>
    )
}