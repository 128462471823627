import React, { useEffect } from "react";
import styled from "styled-components";
import { ConfirmButton } from "../../components/ConfirmButton.tsx";
import { useNavigate, useLocation } from "react-router-dom";
import RubyText from "../../components/RubyText.tsx";
import Character from "../../components/Character.tsx";
import Modal from "../../components/Modal.tsx";
import Button from "../../components/Button.tsx";
import axios from "axios";
import { useState } from "react";
import common from '../../common/common.ts';
import { Asset } from '../../models/Asset.ts';
import Comfirm from "../../components/Comfirm.tsx";
import Message from "../../components/Message.tsx";
import { UseCode } from "../../providers/CodeProvider.tsx";
import { UseUser, UseUserRefresher } from "../../providers/UserProvider.tsx";
import Particles from "react-tsparticles";

const ShopImg = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(./img/shop.png);
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;
`;
const UserPoint = styled.div`
    width: fit-content;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 5px;
    position: relative;
    background-color: #161616f0;
    text-align: center;
    color: #fff;
    font-size: 1em;
    bottom: calc(-90% + 1.5rem);
    //afterでhapi-.svgを設定
    /* &::after{
        content: '';    
        position: absolute;
        right: 5px; 
        bottom: 0;
        width: 1.2rem;
        height: 1.2rem;
        background-image: url(./img/hapi-.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        //白色に
        filter: invert(1);
    } */
`;
const Content = styled.main`
    display: flex;
    height:70%;
    margin-top:2em;
    @media (max-width: 900px) {
        margin-top:0px
    }
`;

const FlexDiv = styled.div`
    
    height: 100%;
    overflow: overlay;
    &.left{
        width: 30%;
    }
    &.right{
        width: 67%;
    }
    &::-webkit-scrollbar{
        width: 10px;
        margin-right: 10px;
        display:block;
    }
    &::-webkit-scrollbar-thumb{
        background-color: green;
        border-radius: 5px;
    }

`;
const TabArea = styled.div`
    width: 67%;
    height: 40px;
    display: flex; 
    border : 2px solid #000;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #000;
    padding: 0;
    margin:0;
    text-align: center;
    position: fixed;
    z-index: 99;
    @media (max-width: 900px) {
        font-size: 0.6em;
    }
`;
const ItemTab = styled.div`
    width: 17%;
    height: 100%;
    padding: 10px 0 10px 0;
    border-right: 2px solid #000;
    background-color: #dadada;
    &:first-child{
        border-top-left-radius: 10px;
    }
    &:last-child{
        border-right: none;
        border-top-right-radius: 10px;
    }
    &.active{
        background-color: #faf743;
    }
`;
const ListHeader = styled.div`
    background-color: #2121d4;
    color: white;
    padding: 0;
    margin:0;
    margin-top:40px;
    text-align: center;
    position: fixed;
    width: 67%;
    height: 40px;
    z-index: 99;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    @media (max-width: 900px) {
        font-size: 0.6em;
        height: 20px;
    }
`;

const ItemGridContainer = styled.div`
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    @media (max-width: 900px) {
        margin-top: 60px;
    }
    
`;
const ItemGrid = styled.div`
    width: 20%;
    border: 1px solid black;
    box-sizing: border-box;
    background-color: #fff;
    &.purchaed{
        background-color: #b9b9b992;
    }
`;

const ItemImg = styled.div<{ src: string }>`
    width: 80%;
    height: 5em;
    margin: 5px;
    //srcがある場合は、srcを設定する
    background-image: url(${props => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;
const ItemParticle = styled.div`
    width: 95%;
    height: 5em;
    margin: 5px;
    background-color: #000000b2;
`;
const ItemPrice = styled.span`
    display: block;
    background-color: #1fb41c;
    width: 90%;
    margin: 5px;
    border-radius: 10px;
    color: #fff;
    @media (max-width: 900px) {
        font-size:xx-small;
    }
`;

const IsAcquired = styled.span`
    display: block;
    background-color: #ecaa2e;
    width: 90%;
    margin: 5px;
    border-radius: 10px;
    color: #fff;
    font-size:smaller;
    @media (max-width: 900px) {
        font-size:xx-small;
    }
`;
const DescriptionItemImg = styled.div<{ src: string }>`
    width: 70%;
    height: 30%;
    margin-top: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.src});
`;
const DescriptionItemPaticle = styled.div`
    width: 70%;
    height: 30%;
    margin-top: 20px;
    background-color: #000000b2;
`;
const DescriptionItemText = styled.span`
    width: 70%;
    display: block;
    padding-top: 20px;
    border-bottom: 2px solid #000;
    text-align: left;
`;
const Menu = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

const ModalFooter = styled.div`
    position: sticky;
    bottom: 0px;
    background-color: #fff;
`;

export const Shop = () => {
    //ショップアイテムの取得
    const [ShopItems, setShopItems] = useState<Asset[]>([]);
    const [displaiedItem, setDisplaiedItem] = useState<Asset[]>([]);
    const [selectedTabNo, setSelectedTabNo] = useState<number>(1);

    const [particlesOptions, setParticlesOptions] = useState({});
    //コードの取得
    const codes = UseCode();
    //ユーザー情報の取得
    const user = UseUser();
    //ユーザー情報の更新
    const refreshUserData = UseUserRefresher();

    /**
     * ショップアイテムの取得
     */
    const getShopItems = async () => {
        const response = await axios.get(common.addAccessToken(process.env.REACT_APP_API_ASSET_GET));
        if (response) {
            console.log(response.data.data['particle']);
            response.data.data['particle'].map((e: { asset_id: number; asset_path: string; }) => {
                if (e.asset_path != '') {
                    getParticlesOptions(e.asset_id, e.asset_path);
                }
            });
            setShopItems(response.data.data);
        }
    }
    /**
     * パーティクルの設定取得
     * @param asset_id
     * @param url
     * @returns
    */
    const getParticlesOptions = async (asset_id: number, url: string) => {
        const response = await axios.get(url);
        if (response) {
            // フルスクリーンを無効化
            response.data.fullScreen = { enable: false };
            // particlesOptionsをオブジェクトとして更新
            setParticlesOptions(particlesOptions => ({ ...particlesOptions, [asset_id]: response.data }));
        }
    }

    //初回のみ実行されるuseEffect
    useEffect(() => {
        getShopItems();
    }, []);

    // ShopItemsが更新されたときに実行されるuseEffect
    useEffect(() => {
        if (ShopItems.length === undefined) {
            tabChange(selectedTabNo);
        }
    }, [ShopItems]);

    const [arrayKey, setArrayKey] = useState<string>('');
    /**
     * タブ切替処理
     * @param tabNo 
     */
    const tabChange = (tabNo: number) => {
        //codesからkubun:400,code:tabNoのデータを取得
        const code = codes.find(e => e.kubun === 400 && e.code === tabNo);
        //asset_typeで絞り込み
        const items = ShopItems[code.kanren1];
        //arrayKeyを設定
        setArrayKey(code.kanren1);
        //displaiedItemを設定
        setDisplaiedItem(items);
        //選択中のタブを設定
        setSelectedTabNo(tabNo);
    }

    // モーダルの表示状態を管理するための状態変数
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Asset | null>(null);

    /**
     * アイテム詳細表示処理
     * @param id 
     */
    const ItemDetail = (id: number) => {
        const item = ShopItems[arrayKey].find(e => e.asset_id === id);
        if (item) {
            setSelectedItem(item);
        }
        setIsModalOpen(true);
    }

    //購入確認用状態変数
    const [isPurchaseComfirm, setIsPurchaseComfirm] = React.useState(false);
    /**
     * 購入確認処理
     */
    const PurchaseComfirm = () => {
        setIsPurchaseFailed(false);
        setIsPurchaseFailedMessage('');
        setIsPurchaseSuccess(false);
        setIsPurchaseComfirm(true);
    }

    //アイテムの購入
    const [isPurchaseSuccess, setIsPurchaseSuccess] = useState(false);
    const [isPurchaseFailed, setIsPurchaseFailed] = useState(false);
    const [isPurchaseFailedMessage, setIsPurchaseFailedMessage] = useState('');
    /**
     * アイテムの購入処理
     */
    const ItemPurchase = () => {

        //購入するアイテムのasset_idと購入数を設定
        const query = selectedItem ? {
            asset_id: selectedItem.asset_id,
            purchases_number: 1
        } : null;
        //queryがnullの場合は、処理を終了する
        if (query === null) {
            return;
        }
        //購入処理
        axios.post(common.addAccessToken(process.env.REACT_APP_API_ASSET_POST), query)
            .then((response) => {
                console.log(response);
                setIsPurchaseSuccess(true);
                //ユーザ情報の再取得
                refreshUserData();
                //2秒後にモーダルを閉じる
                setTimeout(() => {
                    setIsPurchaseSuccess(false);
                    setIsModalOpen(false);
                    setIsPurchaseComfirm(false);
                    getShopItems();
                }, 1000);
            })
            .catch((error) => {
                setIsPurchaseFailed(true);
                setIsPurchaseFailedMessage(error.response.data.report);
                console.log(error);
            });
    }

    return (
        <>
            <h1><RubyText text="アイテムショップ"></RubyText></h1>
            <p><RubyText text="あなたが集めたスポーツの力『ハピー』を使って、ひろばを飾るアイテムがもらえるよ"></RubyText></p>

            <Content>
                <FlexDiv className="left">
                    <ShopImg>
                        <UserPoint><RubyText text={`所持{しょじ}ハピー：${user.point}`}></RubyText></UserPoint>
                    </ShopImg>

                </FlexDiv>
                <FlexDiv className="right">
                    <TabArea>
                        <ItemTab onClick={() => { tabChange(1) }} className={selectedTabNo == 1 ? 'active' : ''}><RubyText text="背景{はいけい}"></RubyText></ItemTab>
                        <ItemTab onClick={() => { tabChange(2) }} className={selectedTabNo == 2 ? 'active' : ''}><RubyText text="背景{はいけい}アイテム"></RubyText></ItemTab>
                        <ItemTab onClick={() => { tabChange(5) }} className={selectedTabNo == 5 ? 'active' : ''}><RubyText text="効果{こうか}"></RubyText></ItemTab>
                        <ItemTab onClick={() => { tabChange(7) }} className={selectedTabNo == 7 ? 'active' : ''}><RubyText text="キャラクター"></RubyText></ItemTab>
                        <ItemTab onClick={() => { tabChange(6) }} className={selectedTabNo == 6 ? 'active' : ''}><RubyText text="エフェクト"></RubyText></ItemTab>
                        <ItemTab onClick={() => { tabChange(4) }} className={selectedTabNo == 4 ? 'active' : ''}><RubyText text="床{ゆか}アイテム"></RubyText></ItemTab>
                    </TabArea>
                    <ListHeader><RubyText text="アイテム一覧{いちらん}"></RubyText></ListHeader>
                    <ItemGridContainer>
                        {displaiedItem && 
                        displaiedItem.map(e =>
                            <ItemGrid key={e.asset_id} onClick={() => { ItemDetail(e.asset_id) }}>
                                {
                                    selectedTabNo == 5 &&
                                    <ItemParticle>
                                        {
                                            particlesOptions[e.asset_id] &&
                                            <Particles id={`particles_${e.asset_id}`} key={e.asset_id} options={particlesOptions[e.asset_id]} />
                                        }
                                    </ItemParticle>
                                }
                                {
                                    selectedTabNo != 5 &&
                                    <ItemImg src={e.asset_path}></ItemImg>
                                }

                                {
                                    e.quantity === 0 &&
                                    <ItemPrice>{e.point}</ItemPrice>
                                }
                                {
                                    e.quantity > 0 &&
                                    <IsAcquired><RubyText text={`${e.quantity}個{こ} 獲得済{かくとくず}み`}></RubyText></IsAcquired>
                                }

                            </ItemGrid>
                        )}
                    </ItemGridContainer>
                </FlexDiv>
            </Content>
            {isModalOpen && selectedItem && (
                <Modal >
                    <h2><RubyText text="アイテムの詳細{しょうさい}"></RubyText></h2>
                    {
                        selectedItem.asset_type === 5 &&
                        <DescriptionItemPaticle>
                            <Particles id={`particles_detail_${selectedItem.asset_id}`} key={selectedItem.asset_id} options={particlesOptions[selectedItem.asset_id]} />
                        </DescriptionItemPaticle>
                    }
                    {
                        selectedItem.asset_type != 5 &&
                        <DescriptionItemImg src={selectedItem.asset_path}></DescriptionItemImg>
                    }
                    
                    <DescriptionItemText>アイテム名：<RubyText text={selectedItem.asset_name}></RubyText></DescriptionItemText>
                    <DescriptionItemText>必要ハピー：{selectedItem.point}</DescriptionItemText>
                    <DescriptionItemText>アイテムの詳細：<RubyText text={selectedItem.asset_description}></RubyText></DescriptionItemText>
                    {/* <DescriptionItemText>持っているハピー：{user.point}</DescriptionItemText> */}
                    <Menu>
                    <Button.Default onClick={() => { setIsModalOpen(false) }}>キャンセル</Button.Default>
                    <Button.Get onClick={() => { PurchaseComfirm() }}></Button.Get>
                    </Menu>
                    

                </Modal>
            )}
            {
                isPurchaseComfirm && (
                    <Comfirm closeEvent={() => { setIsPurchaseComfirm(false) }} agreeEvent={() => { ItemPurchase() }}>
                        <p><RubyText text="このアイテムをもらいますか？"></RubyText></p>
                        {
                            //エラーメッセージがあれば表示
                            isPurchaseFailedMessage != '' &&
                            <Message.Error><RubyText text={isPurchaseFailedMessage}></RubyText></Message.Error>
                        }
                        {
                            isPurchaseSuccess &&
                            <Message.Success><RubyText text="アイテムをもらいました"></RubyText></Message.Success>
                        }
                    </Comfirm>
                )
            }
        </ >
    )
}

export default Shop;