import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom"
import styled from "styled-components";
import { UseUserLand } from "../providers/UserProvider.tsx";

const BackGroundDiv = styled.div<{ $backgroundURL: string }>`
    height:97vh;
    width:100vw;
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;
    display: block;
    text-align: -webkit-center;
    padding-top: 3vh;
    overflow: hidden;
    //background-imageをpropsで受け取り、設定する
    background-image: url(${props => props.$backgroundURL});
    @media (max-width: 900px) {
        overflow-y: scroll;
    }
`;
const BackGroundItem = styled.div<{ $backgroundItemURL: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    right:0;
    margin: auto;
    background: transparent;
    z-index: 0;
    //background-imageをpropsで受け取り、設定する
    background-image: url(${props => props.$backgroundItemURL});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    //backgroundURLが空の場合は、display:noneにする
    display: ${props => props.$backgroundItemURL!='' ? 'block' : 'none'};
`;


/**
 * 背景スキンのコンポーネント
 * @returns 
 */
export default function BackGround(props: { children: React.ReactNode }) {

    const userLand = UseUserLand();
    const location = useLocation();
    let backgroundURL = userLand.background;
    let backgroundItemURL = userLand.back_item;
    //ホームに居る場合は、クラス名称を少し変更する
    if (location.pathname !== '/home') {
        //backgroundURL += 'NoneHome';
        //拡張子の前に「NoneHome」を追加する
        const extension = userLand.background.split('.').pop();
        const fileName = userLand.background.replace('.' + extension, '');
        backgroundURL = fileName + '_noneHome.' + extension;
        backgroundItemURL = '';
    }
    // 背景画像の設定
    return (
        <BackGroundDiv className={`HomeContainer`} $backgroundURL={backgroundURL}>
            <BackGroundItem className="back_item" $backgroundItemURL={backgroundItemURL}/>
            {props.children}
        </BackGroundDiv>
    );
}
