import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Authenticate({ children }: { children: React.ReactElement }) {

    const navigate = useNavigate();
    const location = useLocation();

    const hasSession = sessionStorage.getItem('accessToken');

    //セッションが無ければログインページへ
    useEffect(() => {
        if (hasSession == null) {
            navigate('/login');
            return;
        }

    }, [location])

    return (
        <>
            {children}
        </>
    )
}

