import styled from "styled-components";


export const CharacterContainer = styled.div`
    position: absolute;
    width: 100%;
    top: -35%;
    /* border: 5px dotted #fcff6a; */
    left:0;
    right:0;
    margin: auto;
`;