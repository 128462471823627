import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home.tsx";
import Login from "../pages/login/Login.tsx";
import Authenticate from "../auth/authenticate.tsx";
import React, { useEffect } from "react";
import HomeButton from "../components/HomeButton.tsx";
import UserSetting from "../pages/user_setting/UserSetting.tsx";
import Background from "../components/Background.tsx";
import { CourseProvider } from "../providers/CourseProvider.tsx";
import { CompetitionProvider } from "../providers/CompetitionProvider.tsx";
import { ParameterProvider } from "../providers/ParameterProvider.tsx";
import { CodeProvider } from "../providers/CodeProvider.tsx";
import Shop from "../pages/shop/Shop.tsx";
import LandSetting from "../pages/land_setting/LandSetting.tsx";
import Record from "../pages/record/Record.tsx";
import { UserProvider } from "../providers/UserProvider.tsx";
import { SelectCompetition } from "../pages/competition/SelectCompetition.tsx";
import { InputValue } from "../pages/competition/InputValue.tsx";

export const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`location changed:${location.pathname}`);
  }, [location]);

  if (location.pathname === "/" || location.pathname === "") {
    navigate("/home");
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <Authenticate>
              <UserProvider>
                <ParameterProvider>
                  <CodeProvider>
                    <CompetitionProvider>
                      <Background>
                        <HomeButton />
                        <Routes>
                          <Route path="/home" element={<Home />} />
                          <Route
                            path="/competition/*"
                            element={
                              <CourseProvider>
                                <Routes>
                                  <Route path="/" element={<SelectCompetition />} />
                                  <Route
                                    path="/input"
                                    element={<InputValue />}
                                  />
                                </Routes>
                              </CourseProvider>
                            }
                          />
                          <Route path="/shop" element={<Shop />} />
                          <Route path="/land-setting" element={<LandSetting />} />
                          <Route path="/user-setting" element={<UserSetting />} />
                          <Route path="/record" element={<Record />} />
                        </Routes>
                      </Background>
                    </CompetitionProvider>
                  </CodeProvider>
                </ParameterProvider>
              </UserProvider>
            </Authenticate>
          }
        />
      </Routes>
    </>
  );
};
