import React, { useEffect } from "react";
import styled from "styled-components";
import { UseUserLand } from "../../../providers/UserProvider.tsx";
import { useState } from "react";
import { CharacterContainer } from "./CharacterContainer.tsx";
import Character from "../../../components/Character.tsx";


const LandGrid = styled.table`
    height: 98%;
    width:70%;
    border-collapse: collapse;
    transform: perspective(1800px) translateY(75%) rotateX(0deg);
    margin: auto;
    //tbody要素に対して、transformを設定
    tbody {
        //transform: perspective(1800px) translateY(0%) rotateX(45deg);
    }
`;
const GridItem = styled.td<{ $overlay: boolean }>`
    transform: perspective(250px) translateY(15%) rotateX(0deg) skew(0deg);
    filter: drop-shadow(0px 10px 0px #0000008f);
    transform-origin: bottom center;
    position: sticky;
    //overlayがtrueの場合は、z-indexを1にする
    z-index: ${props => props.$overlay ? 1 : -1};
`;
const GridItemImg = styled.img<{ src: string }>`
    position: absolute;
    bottom: 0;
    width: 90%;
    /* height: 100%; */
    border:none;
    z-index: 0;
    //srcを設定
    src: ${props => props.src};
    //srcが空の場合は、display:noneにする
    display: ${props => props.src ? 'block' : 'none'};
    &.row1{
        width:70%;
    }
    &.row2{
        width:80%;
    }
    &.row3{
    }

`;
// ランドグリッドコンテナ
export const LandGridContainer = () => {
    const userLand = UseUserLand();

    return (
        <>
            
            <LandGrid className="LandGrid">
                <thead><tr><td>
                <CharacterContainer>
                    <Character />
                </CharacterContainer>
                </td></tr></thead>
                <tbody>
                    <tr>
                        <GridItem $overlay={false}><GridItemImg src={userLand.land_item.x1y1} className="row1"/></GridItem>
                        <GridItem $overlay={false}><GridItemImg src={userLand.land_item.x2y1} className="row1"/></GridItem>
                        <GridItem $overlay={false}><GridItemImg src={userLand.land_item.x3y1} className="row1"/></GridItem>
                        <GridItem $overlay={false}><GridItemImg src={userLand.land_item.x4y1} className="row1"/></GridItem>
                        <GridItem $overlay={false}><GridItemImg src={userLand.land_item.x5y1} className="row1"/></GridItem>
                        <GridItem $overlay={false}><GridItemImg src={userLand.land_item.x6y1} className="row1"/></GridItem>
                    </tr>
                    <tr>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x1y2} className="row2"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x2y2} className="row2"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x3y2} className="row2"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x4y2} className="row2"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x5y2} className="row2"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x6y2} className="row"/></GridItem>
                    </tr>
                    <tr>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x1y3} className="row3"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x2y3} className="row3"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x3y3} className="row3"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x4y3} className="row3"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x5y3} className="row3"/></GridItem>
                        <GridItem $overlay={true}><GridItemImg src={userLand.land_item.x6y3} className="row3"/></GridItem>
                    </tr>

                </tbody>

            </LandGrid>
            
        </>
    )
};