import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button.tsx";
import Character from "../../components/Character.tsx";
import { ConfirmButton } from "../../components/ConfirmButton.tsx";
import RubyText from "../../components/RubyText.tsx";
import InputText from "../../components/InputText.tsx";
import { Competition } from "../../models/Competition.ts";
import axios from "axios";
import List from "./components/List.tsx";
import ErrorMessage from "./components/ErrorMessage.tsx";

const Container = styled.div`
    display: flex;
    margin: 50px 50px 10px 50px;
    height: 50vh;
    width: 90%;
    @media (max-width: 900px) {
        margin: 10px 50px 10px 50px;
    }
`;

const CharacterArea = styled.div`
    width:25%;
`;

const CompetitionArea = styled.main`
    gap: 100px;
    width:50%;
    background-color: rgba(255,255,255,0.7);
    border-radius:10px;
    border: 3px solid white;
    padding:20px;
    box-sizing: border-box;
    text-align: left;
    @media (max-width: 900px) {
        width: 70%;
        overflow:scroll;
    }
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
`;

const CompetitionDescription = styled.div`
    background: white;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-top: 10px;
    & > div:not(:last-child){
        border-bottom: 1px dashed black;
    }
`;

const MyInput = styled(InputText)`
    text-align: right;
    display: inline-flex;
    &&& {
        width: 70%;
        margin-right: 10px;
        text-align: left;
    }
`;
const MyInputTime = styled(InputText)`
    display: inline-flex;
    text-align: right;
    &&& {
        width: 4em;
        margin-right: 10px;
        text-align: left;
    }
`;

const MyInputTimeDiv = styled.div`
    margin-right: 5px;
    display: flex;
    align-items: end;
    &&& {
        && > div > div:first-child {
            width:4em;
        }
    }
`;
const Menu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    //div要素を横並びにする
    & > div.left{
        width:55%;
        text-align: -webkit-left;
    }
    & > div.right{
        width:45%;
        text-align: -webkit-right;
    }
`;

function timeToMinutes(hours: number, minutes: number, seconds: number): number {
    // 時間を分に変換
    const totalMinutes = hours * 60 + minutes + seconds / 60;
    // 四捨五入してDecimal(5,2)相当の精度で返す
    return Math.round(totalMinutes * 100) / 100;
}

export const InputAmbition = (props: { OnSetEvent: () => void, Competition: Competition }) => {

    // ユーザーの入力した目標値
    const [ambitionScore, setAmbitionScore] = useState<string>('');
    function handleInputChange(value: string) {
        setAmbitionScore(value);
    }

    const [time, setTime] = useState<{
        Hours: string,
        Minutes: string,
        Seconds: string,
    }>({
        Hours: "",
        Minutes: "",
        Seconds: ""
    });
    function handleTimeInputChange(type: string, value: string) {

        let newValue = {
            Hours: time.Hours,
            Minutes: time.Minutes,
            Seconds: time.Seconds
        };

        switch (type) {
            case "Hours":
                newValue.Hours = value;
                break;
            case "Minutes":
                newValue.Minutes = value;
                break;
            case "Seconds":
                newValue.Seconds = value;
                break;
        }

        setTime(newValue);

        let convertedValue = timeToMinutes(parseInt(newValue.Hours) || 0,
            parseInt(newValue.Minutes) || 0,
            parseInt(newValue.Seconds) || 0);

        setAmbitionScore(String(convertedValue));

    }



    const [errorMessage, setErrorMessage] = useState<string>('');

    const onClickConfirm = async () => {

        // エラーメッセージクリア
        setErrorMessage('');

        //必須チェック
        if (ambitionScore === '') {
            setErrorMessage('目標{もくひょう}を入力{にゅうりょく}してください');
            return;
        }
        if (Number.parseFloat(ambitionScore) === 0) {
            setErrorMessage('目標{もくひょう}は0以外{いがい}を設定{せってい}してください');
            return;
        }


        // 更新する目標内容
        const query = {
            competition_id: props.Competition.competition_id,
            ambitions_score: ambitionScore
        };

        let apiUrl = process.env.REACT_APP_API_URL || '';

        await axios.post(`${apiUrl}/ambition/register/${sessionStorage.getItem('accessToken')}`, query)
            .then((response) => {
                console.log(response);
            })

        props.OnSetEvent();
    }

    return (
        <>
            <h1><RubyText text="目標{もくひょう}を決{き}める" /></h1>
            <Container>
                <CharacterArea>
                    <Character />
                </CharacterArea>
                <CompetitionArea>
                    <RubyText text="マイペースコースで、はじめての種目{しゅもく}に挑戦{ちょうせん}しました。" />

                    <CompetitionDescription>
                        <List.Component title="コース選択{せんたく}" value={props.Competition.course_name} />
                        <List.Component title="種目{しゅもく}" value={props.Competition.competition_name} />
                    </CompetitionDescription>

                    <p style={{ marginTop: "10px" }}>
                        <RubyText text="マイペースコースの種目{しゅもく}に挑戦{ちょうせん}するには、目標{もくひょう}の設定{せってい}が必要{ひつよう}です。" />
                    </p>
                    <p>
                        <RubyText text="この種目{しゅもく}のあなたの目標{もくひょう}を入力{にゅうりょく}してください。" />
                    </p>

                    {
                        props.Competition.score_unit === 4 ?
                            <>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <label style={{ width: "10em" }}><RubyText text={"記録{きろく}"} /></label>
                                    <MyInputTimeDiv>
                                        <MyInputTime
                                            OnChangeText={(value) => handleTimeInputChange("Hours", value)}
                                            Name='RecordHour'
                                            Value={time.Hours}
                                            Type='integer'
                                            useCustomKeyboard={true}
                                        />
                                        <span><RubyText text={"時間{じかん}"} /></span>
                                    </MyInputTimeDiv>
                                    <MyInputTimeDiv>
                                        <MyInputTime
                                            OnChangeText={(value) => handleTimeInputChange("Minutes", value)}
                                            Name='RecordMinute'
                                            Value={time.Minutes}
                                            Type='integer'
                                            useCustomKeyboard={true}
                                        />
                                        <span><RubyText text={"分{ふん}"} /></span>
                                    </MyInputTimeDiv>
                                    <MyInputTimeDiv>
                                        <MyInputTime
                                            OnChangeText={(value) => handleTimeInputChange("Seconds", value)}
                                            Name='RecordSecond'
                                            Value={time.Seconds}
                                            Type='integer'
                                            useCustomKeyboard={true}
                                        />
                                        <span><RubyText text={"秒{びょう}"} /></span>
                                    </MyInputTimeDiv>
                                </div>
                            </>
                            :
                            <div style={{ marginTop: "5px", width: "100%" }}>
                                <MyInput
                                    Label='目標{もくひょう}'
                                    OnChangeText={(value) => handleInputChange(value)}
                                    Name='StudentID'
                                    Value={ambitionScore}
                                    Type={props.Competition.score_value_type}
                                    useCustomKeyboard={true}
                                />
                                <span><RubyText text={props.Competition.score_unit_name} /></span>
                            </div>
                    }


                    <ErrorMessage message={errorMessage} />
                </CompetitionArea>
            </Container >
            <Menu>
                <div className="right"><Button.Back /></div>
                <div className="left"><ConfirmButton onClick={onClickConfirm} /></div>
            </Menu>
        </>
    )

}

export default InputAmbition;