import styled from "styled-components";

export const LandContainer = styled.div`
    width: 100%;
    height: 50%;
    margin: 0 auto;
    position: relative;
    background: transparent;
    /* transform: perspective(1000px) translateY(100%) rotateX(60deg); */
    transform-origin: bottom center;
`;