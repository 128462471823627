import styled from "styled-components";

export const LeftContainer = styled.div`
    width: 25%;
    height: 45%;
    overflow-y: scroll;
    position: absolute;
    top:0;
    left:0;
    padding-left: 10px;
    z-index: 3;
    text-align: left;
    /* &::-webkit-scrollbar{
        width: 10px;
        margin-right: 10px;
        display:block;
    }
    &::-webkit-scrollbar-thumb{
        background-color: green;
        border-radius: 5px;
    } */
`;