import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const LoadingStyled = styled.div<{ $isLoading: boolean , $axiosMethod:string }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.3);
  background-image: url(./img/loading.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  animation: ${props => props.$isLoading ? fadeIn : fadeOut} 1s ease-in-out;
  display: none; /* デフォルトは非表示 */
  // axiosMethod == 'post'の時はbackground-imageを適用しない
  ${props => props.$axiosMethod == 'post' && `background-image: none;`}
`;
const LoadingSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-top-color: #00ff22;
  animation: loading 1s linear infinite;
  @keyframes loading {
    100% {
      transform: rotate(360deg);
    }
  }
`;
//isLoadingとaxiosMethodを受け取る
export default function Loading(props: { isLoading: boolean; axiosMethod: string | 'get';}){
    return (
      <LoadingStyled $isLoading={props.isLoading} $axiosMethod={props.axiosMethod}>
      <LoadingSpinner />
    </LoadingStyled>
    );
}

