import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button.tsx";
import InputText from "../../components/InputText.tsx";
import Background from "../../components/Background.tsx";
import RubyText from "../../components/RubyText.tsx";
import { UseUser, UseUserRefresher } from "../../providers/UserProvider.tsx";
import Message from "../../components/Message.tsx";
import common from "../../common/common.ts";
import axios from "axios";

const Main = styled.main`
    background-color: #7bf37fb7;
    width: 50vw;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 5px solid #fff;
    border-radius: 5px;
    /* max-height: 400px; */
    min-height: 250px;
    @media (max-width: 900px) {
    width: 80vw; /* スマホサイズの場合の幅 */
    min-height: inherit;
    }
`;

const InputAreaDiv = styled.div`
    display: contents;
`;
const Menu = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;
export default function UserSetting() {

    const [ready, setReady] = useState<boolean>(false);
    const [nickName, setNickName] = useState<string>();
    const [height, setHeight] = useState<number>();
    const [weight, setWeight] = useState<number>();
    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordConfirm, setNewPasswordComfirm] = useState<string>('');
    const [requirePasswordChange, setRequirePasswordChange] = useState<boolean>(false);
    const [isValidate, setIsValidate] = useState<boolean>(true);
    const [validateMessage, setValidateMessage] = useState<string>('');
    const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
    const navigate = useNavigate();
    const user = UseUser();
    //ユーザー情報の更新
    const refreshUserData = UseUserRefresher();

    useEffect(() => {
        if (user?.is_required_setting) {
            //ユーザー情報が未設定の場合は、パスワード変更が必須
            setRequirePasswordChange(true);
        }
        setNickName(user?.nickname);
        setHeight(user?.height);
        setWeight(user?.weight);
        setReady(true);
    }, [user]);

    const validateInputs = () => {
        setIsValidate(true);
        setValidateMessage('');
        // ニックネームが空でないことを確認
        if (!nickName) {
            setValidateMessage('ニックネームを入力{にゅうりょく}してください。');
            return false;
        }
        //ニックネームはひらがな、カタカナのみ
        if (!common.isHiragana(nickName) && !common.isKatakana(nickName)) {
            setValidateMessage('ニックネームはひらがな、カタカナで入力{にゅうりょく}してください。');
            return false;
        }

        // 身長のチェック
        if(height){
            //少数が含まれているかチェック
            if (!Number.isInteger(height)) {
                //小数点以下が2桁以上の場合はエラー
                if (common.getDecimalPointLength(height) > 1) {
                    setValidateMessage('身長{しんちょう}は小数点1桁{しょうすうてん１けた}までの数字{すうじ}で入力{にゅうりょく}してください。');
                    return false;
                }
            }
            //50cm以下はエラー
            if (height > 0 && height < 50) {
                setValidateMessage('身長{しんちょう}の値{あたい}が小{ちい}さすぎます。');
                return false;
            }
            //200cm以上はエラー
            if (height > 200) {
                setValidateMessage('身長{しんちょう}の値{あたい}が大{おお}きすぎます。');
                return false;
            }
        }
        
        // 体重のチェック
        if(weight){
            //少数が含まれているかチェック
            if (!Number.isInteger(weight)) {
                //小数点以下が2桁以上の場合はエラー
                if (common.getDecimalPointLength(weight) > 1) {
                    setValidateMessage('体重{たいじゅう}は小数点1桁{しょうすうてん１けた}までの数字{すうじ}で入力{にゅうりょく}してください。');
                    return false;
                }
            }
            //10kg以下はエラー
            if (weight > 0 && weight < 10) {
                setValidateMessage('体重{たいじゅう}の値{あたい}が小{ちい}さすぎます。');
                return false;
            }
            //150kg以上はエラー
            if (weight > 150) {
                setValidateMessage('体重{たいじゅう}の値{あたい}が大{おお}きすぎます。');
                return false;
            }
        }

        // パスワードが空でないことを確認
        if (requirePasswordChange && !newPassword) {
            setValidateMessage('パスワードを入力{にゅうりょく}してください。');
            return false;
        }
        // パスワード(確認)が空でないことを確認
        if (requirePasswordChange && newPassword && !newPasswordConfirm) {
            setValidateMessage('パスワード(確認{かくにん})を入力{にゅうりょく}してください。');
            return false;
        }
        
        // パスワードが一致していることを確認
        if (requirePasswordChange && newPassword !== newPasswordConfirm) {
            setValidateMessage('パスワードが一致{いっち}しません。');
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        // 入力値のバリデーション
        if (!validateInputs()) {
            console.log('validate error');
            setIsValidate(false);
            return;
        }
        // ユーザー情報の更新
        const query = {
            nickname: nickName,
            height: height,
            weight: weight,
            new_password: newPassword
        };
        console.log(query);
        axios.post(common.addAccessToken(process.env.REACT_APP_API_STUDENT_POST), query)
            .then((response) => {
                setUpdateSuccess(true);
                //ユーザ情報の再取得
                refreshUserData();
                //2秒後にホームへ
                setTimeout(() => {
                    navigate('/home');
                }, 2000);
            })
            .catch((error) => {
                setValidateMessage(error.response.data.report );
                setIsValidate(false);
            });
    };

    return (
        <>
            {
                // ユーザー情報が未設定の場合は、パスワード変更が必須
                ready &&
                <>
                    <h1>はぴスポをはじめる</h1>
                    {
                        //エラーメッセージがあれば表示
                        !isValidate &&
                        <Message.Error><RubyText text={validateMessage}></RubyText></Message.Error>
                    }
                    {
                        updateSuccess &&
                        <Message.Success><RubyText text="更新{こうしん}が完了{かんりょう}しました"></RubyText></Message.Success>
                    }
                    <Main>
                        <p><RubyText text="あなたの情報{じょうほう}を入力{にゅうりょく}してね"></RubyText></p>
                        <InputAreaDiv>
                            <InputText
                                Label='ニックネーム'
                                Name='nickname'
                                Type='text'
                                InitialValue={nickName}
                                OnChangeText={(value: string) => setNickName(value)}
                                Unit="さん"
                            />
                            <InputText
                                Label='身長{しんちょう}'
                                Name='height'
                                Type='number'
                                InitialValue={height}
                                OnChangeText={(value: number) => setHeight(value)}
                                Unit="cm"
                            />
                            <InputText
                                Label='体重{たいじゅう}'
                                Name='weight'
                                Type='number'
                                InitialValue={weight}
                                OnChangeText={(value: number) => setWeight(value)}
                                Unit="kg"
                            />
                        </InputAreaDiv>
                        {
                            requirePasswordChange &&
                            <>
                                <p><RubyText text="パスワードは必{かなら}ず変更{へんこう}してね"></RubyText></p>
                                <InputText
                                    Label='新{しん}パスワード'
                                    Name='new_password'
                                    Type='password'
                                    InitialValue={newPassword}
                                    OnChangeText={(value: string) => setNewPassword(value)}
                                />
                                <InputText
                                    Label='新{しん}パスワード<br>（確認{かくにん}）'
                                    Name='new_password_confirm'
                                    Type='password'
                                    InitialValue={newPasswordConfirm}
                                    OnChangeText={(value: string) => setNewPasswordComfirm(value)}
                                />
                            </>
                        }
                    </Main>
                    <Menu>
                    {
                        !requirePasswordChange &&
                        <Button.Back ></Button.Back>
                    }
                    <Button.Decision onClick={handleSubmit}></Button.Decision>
                    </Menu>
                    

                    
                </>
            }
        </>
    )
}