import React from "react";
import styled from "styled-components";
import RubyText from "../../../components/RubyText.tsx";

export const List = {
    Container: styled.div`
        padding: 5px 0 5px 5px;
    `,

    Title: styled.label`
        display: inline-block;
        width: 10em;
    `,

    Component: (props: { title: string, value: string }) => {
        return (
            <List.Container>
                <List.Title><RubyText text={props.title} /></List.Title>
                <span><RubyText text={props.value} /></span>
            </List.Container>
        )
    }
};

export default List;