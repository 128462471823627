import React, { createContext, useContext, useEffect, useState } from "react";

const CourseContext = createContext<{
    courseName: string | undefined,
    courseID: string | undefined,
    setCourseID: React.Dispatch<React.SetStateAction<string>>,
}>({
    courseName: '',
    courseID: '',
    setCourseID: () => { }
});

export const useCourse = () => useContext(CourseContext);


export const CourseProvider = (props: { children: React.ReactElement }) => {

    const [courseID, setCourseID] = useState<string>('');
    const [courseName, setCourseName] = useState<string>(getCourseName(courseID));

    useEffect(() => {
        setCourseName(getCourseName(courseID));
    }, [courseID]);

    useEffect(() => {
    }, [courseName]);

    return <CourseContext.Provider value={{ courseName, courseID, setCourseID }}>{props.children}</CourseContext.Provider>
}

const getCourseName = (courseType: string) =>
    courseType === 'ranking' ? 'ランキングコース' :
        courseType === 'enjoy' ? 'エンジョイコース' :
            courseType === 'mypace' ? 'マイペースコース' :
                courseType === 'hapihapi' ? 'はぴはぴコース' :
                    'コースが不明です。';