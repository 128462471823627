import { co } from "@fullcalendar/core/internal-common";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Content = styled.div`
    width: 100%;
    height: 25%;
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;
`;
const FooterLeftArea = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    position: relative;
`;
const FooterRightArea = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    position: relative;
`;
const SlideButtons = styled.div<{ slide: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    transition: transform 0.3s ease-out;
    &.left{
        left:-100%;
        animation: ${props => props.slide ? 'slideInLeft 0.3s forwards' : 'slideOutLeft 0.3s forwards'};
    }
    &.right{
        right:-100%;
        animation: ${props => props.slide ? 'slideInRight 0.3s forwards' : 'slideOutRight 0.3s forwards'};
    }
    @keyframes slideInLeft {
        from {
            left:-100%;
        }
        to {
            left:0;
        }
    }
    @keyframes slideOutLeft {
        from {
            left:0;
        }
        to {
            left:-100%;
        }
    }

    @keyframes slideInRight {
        from {
            right:-100%;
        }
        to {
            right:0;
        }
    }
    @keyframes slideOutRight {
        from {
            right:0;
        }
        to {
            right:-100%;
        }
    }
`;

const FooterButton = styled.div<{ $img: string, slide?: boolean }>`
    background-image: url(${props => props.$img});
    width: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.left{
        position: absolute;
        left: 0;
        height: 100%;
        display:${props => !props.slide ? 'block' : 'none'};
    }
    &.right{
        position: absolute;
        right: 0;
        height: 100%;
        display:${props => !props.slide ? 'block' : 'none'};
    }
`;

export default function FooterContainer() {
    const navigate = useNavigate();
    const permanentCoursePage = (courseID: string) => {
        navigate("/competition", { state: { courseID: courseID } });
    }
    const parmanentRecordPage = (tabName: string) => {
        navigate("/record", { state: { initialTab: tabName } });
    }
    const [leftSlide, setLeftSlide] = React.useState(false);
    const [rightSlide, setRightSlide] = React.useState(false);
    const slide = (direction: string) => {
        if (direction === 'left') {
            setLeftSlide(!leftSlide);
        } else {
            setRightSlide(!rightSlide);
        }
    }
    // 外部のタッチイベントを検出するロジック
    useEffect(() => {
        const handleOutsideTouch = (event) => {
            console.log(event.target);
            // SlideButtons以外のエリアがタッチされたかを確認
            if (!event.target.closest('.slide-buttons')) {
                // 左右のスライドを閉じる
                setLeftSlide(false);
                setRightSlide(false);
            }
        };
        // イベントリスナーを追加
        document.addEventListener('touchstart', handleOutsideTouch);
        //document.addEventListener('click', handleOutsideTouch);
        // クリーンアップ関数でイベントリスナーを削除
        return () => {
            document.removeEventListener('touchstart', handleOutsideTouch);
            //document.removeEventListener('click', handleOutsideTouch);
        };
    }, []);
    
    return (
        <Content>
            <FooterLeftArea>
                <FooterButton className="left" $img="./img/button-record-input.png" onClick={() => slide('left')} slide={leftSlide}></FooterButton>
                <SlideButtons className="left slide-buttons" slide={leftSlide}>
                    <FooterButton $img="./img/button-ranking.png" onClick={() => permanentCoursePage('ranking')}></FooterButton>
                    <FooterButton $img="./img/button-enjoy.png" onClick={() => permanentCoursePage('enjoy')}></FooterButton>
                    <FooterButton $img="./img/button-mypace.png" onClick={() => permanentCoursePage('mypace')}></FooterButton>
                    <FooterButton $img="./img/button-hapihapi.png" onClick={() => permanentCoursePage('hapihapi')}></FooterButton>
                </SlideButtons>
            </FooterLeftArea>

            <FooterRightArea>
                <FooterButton className="right" $img="./img/button-record-show.png" onClick={() => slide('right')} slide={rightSlide}></FooterButton>
                <SlideButtons className="right slide-buttons" slide={rightSlide}>
                    <FooterButton $img="./img/button-record-personal.png" onClick={() => parmanentRecordPage('personal')}></FooterButton>
                    <FooterButton $img="./img/button-record-course.png" onClick={() => parmanentRecordPage('record')}></FooterButton>
                    <FooterButton $img="./img/button-record-ranking.png" onClick={() => parmanentRecordPage('ranking')}></FooterButton>
                    <FooterButton $img="./img/button-record-calendar.png" onClick={() => parmanentRecordPage('calendar')}></FooterButton>
                </SlideButtons>
            </FooterRightArea>

        </Content>
    )
}
