import React from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import RubyText from './RubyText.tsx';

const Button = {
  Default: styled.button`
        border: 2px solid #fff;
  padding: 10px;
  border-radius: 10px;
  background: repeating-linear-gradient(
    -45deg,
    #20b31d,
    #20b31d 5px,
    #27c624 5px,
    #27c624 10px
  );
  font-weight: bold;
  font-size: 0.7rem;
  color: #fff;
  text-shadow: #6d6443 1px 0 10px 1px 0 10px;
  margin-top: 10px;
  display: block;
  min-width: 250px;
  //danger
  &.danger{
    background: repeating-linear-gradient(
      -45deg,
      #b31d1d,
      #b31d1d 5px,
      #c62424 5px,
      #c62424 10px
    );
  }
  &.warning{
    background: repeating-linear-gradient(
      -45deg,
      #f48322,
      #f48322 5px,
      #f6a024 5px,
      #f6a024 10px
    );
  }
  &.gray{
    background: repeating-linear-gradient(
      -45deg,
      #b3b3b3,
      #b3b3b3 5px,
      #c6c6c6 5px,
      #c6c6c6 10px
    );
  }
  &.small{
      padding: 0px;
      margin: 0px;
      width:fit-content;
  }
  &.modal-close{
    position:sticky;
    bottom: 10px;
    //中央
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 900px) {
    font-size: 0.5rem;
    padding:5px 20px 5px 20px;
    min-width: 150px;
    }
    `
  ,
  Back:() => {
    const navigate = useNavigate();
    return <Button.Default onClick={() => navigate(-1)}><RubyText text="戻{もど}る"></RubyText></Button.Default>;
  },
  Start: styled.button`
    width: 300px;
    height: 5rem;
    background-color: transparent;
    background-image: url('./img/button-start.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    margin-top: 10px;
    @media (max-width: 900px) {
      height: 3rem;
    }
    `
  ,
  Login: styled.button`
    width: 300px;
    height: 5rem;
    background-color: transparent;
    background-image: url('./img/button-login.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    margin-top: 10px;
    @media (max-width: 900px) {
      height: 3rem;
    }
    `
  ,
  Decision: styled.button`
    width: 300px;
    height: 5rem;
    background-color: transparent;
    background-image: url('./img/button-decision.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    margin-top: 10px;
    @media (max-width: 900px) {
      height: 3rem;
    }
    `
  ,
  Get: styled.button`
    width: 300px;
    height: 5rem;
    background-color: transparent;
    background-image: url('./img/button-get.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    margin-top: 10px;
    @media (max-width: 900px) {
      height: 3rem;
    }
    `

};

export default Button;