
const common = {
    /**
 * アクセストークンを付与したURLを返す
 * @param url APIのURL
 * @returns アクセストークンを付与したURL
 */
    addAccessToken: (url: string | undefined) => {
        if (url === undefined) return '';
        const accessToken = sessionStorage.getItem('accessToken');
        console.log(`${url}/${accessToken}`);
        return `${url}/${accessToken}`;
    },
    isHiragana: (str: string) => {
        return str.match(/^[ぁ-んー　]*$/);
    },
    isKatakana: (str: string) => {
        return str.match(/^[ァ-ヶー　]*$/);
    },
    /**
 * 小数点の桁数を返す
 * @param url APIのURL
 * @returns アクセストークンを付与したURL
 */
    getDecimalPointLength: (num: number) => {
        return num.toString().split('.')[1]?.length || 0;
    }
}


export default common;