import styled from "styled-components";
import login from '../../../assets/img/background_start.png';

export const StartContainer = styled.div`
    height:90vh;
    width:100vw;
    background-image: url(${login});
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;
    display: block;
    text-align: -webkit-center;
    padding-top: 10vh;
    overflow: hidden;
`;