/**
 * 種目情報
 */
export interface Competition {
    course_id: number;
    course_name: string;
    grade_id: number;
    grade_name: string;
    competition_season: number;
    season_name: string;
    competition_season_start: string;
    competition_season_end: string;
    competition_id: number;
    competition_name: string;
    competition_description: string;
    threshold_value: number;
    point: number;
    score_type: number;
    score_unit: number;
    score_unit_name: string;
    score_value_type: string;
    score_list: string;
    link: string;
    sort_no: number;
    del_flg: number;
}

export const defaultCompetition: Competition = {
    course_id: 0,
    course_name: '',
    grade_id: 0,
    grade_name: '',
    competition_season: 0,
    season_name: '',
    competition_season_start: '',
    competition_season_end: '',
    competition_id: 0,
    competition_name: '',
    competition_description: '',
    threshold_value: 0,
    point: 0,
    score_type: 0,
    score_unit: 0,
    score_unit_name: '',
    score_value_type: '',
    score_list: '',
    link: '',
    sort_no: 0,
    del_flg: 0,
} as Competition;