import React, { createContext, useState, useEffect ,useContext} from 'react';
import axios from 'axios';
import common from '../common/common.ts';
import { Parameter } from '../models/Parameter.ts';
import Loading from '../components/Loading.tsx';

// Contextの作成
export const ParameterContext = createContext<Parameter | null>(null);

export const UseParameter = () => useContext(ParameterContext);

// Contextプロバイダコンポーネント
export const ParameterProvider = ({ children }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        // APIリクエストの実行
        const fetchData = async () => {
            try {
                const response = await axios.get(common.addAccessToken(process.env.REACT_APP_API_PARAM_GET));
                setData(response.data.data);
            } catch (error) {
                console.error("APIからデータを取得できませんでした:", error);
            }
        };

        fetchData();
    }, []);

    if(data === null){
        //return <Loading isLoading={true} />;
    }
    return (
        <ParameterContext.Provider value={data}>
            {children}
        </ParameterContext.Provider>
    );
};
