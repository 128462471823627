
import { BrowserRouter, Routes } from "react-router-dom";
import './App.css';
import { AppRoutes } from "./routes/index.js";
import { Suspense } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import React from 'react';
import axios from 'axios';
import Loading from '../src/components/Loading.tsx';
import { Navigate } from 'react-router-dom';

function App() {
  const [isloading, setIsLoading] = useState(false);
  const [axiosMethod, setAxiosMethod] = useState('get');
  useEffect(() => {
    const handleStart = () => setIsLoading(true);
    const handleFinish = () => setIsLoading(false);

    axios.interceptors.request.use(config => {
      //リクエストメソッドを取得
      setAxiosMethod(config.method);
      handleStart();
      return config;
    }, error => {
      handleFinish();
      return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
      handleFinish();
      return response;
    }, error => {
      //レスポンスコードが99（ＡＰＩキー関連のエラー）の場合、セッションをクリア
      const response = JSON.parse(error.request.responseText);
      console.log(response);
      if(response.result == "99"){
        sessionStorage.removeItem('accessToken');
        //Navigate('/login');
        window.location.href = '/login';
      }
      handleFinish();
      return Promise.reject(error);
    });
  }, []);

  //本番環境かどうか(REACT_APP_ENVを参照)
  const isProduction = process.env.REACT_APP_ENV === 'production';
  
  return (
    <>
      <BrowserRouter basename={isProduction ? '' : ''}>
        <Loading $isLoading={isloading} $axiosMethod={axiosMethod}/>
        <Suspense fallback={() => <div></div>}>
          <AppRoutes />
        </Suspense>
      </BrowserRouter>
    </>
  )
}

export default App;
