import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import RubyText from "../../../components/RubyText.tsx";
import Modal from "../../../components/Modal.tsx";
import Button from "../../../components/Button.tsx";
import axios from "axios";
import { useState } from "react";
import common from '../../../common/common.ts';
import Message from "../../../components/Message.tsx";
import { UseCode } from "../../../providers/CodeProvider.tsx";
import { UseUser, UseUserRefresher } from "../../../providers/UserProvider.tsx";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import jaLocale from '@fullcalendar/core/locales/ja';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { useCallback } from 'react';

const ContinuousDays = styled.div`
    width: 100%;
    display: flex;
    font-size: 1.2em;
    font-weight: bold;
    @media (max-width: 900px) {
            font-size: 0.8em;
    }
`;
const ContinuousDaysChild = styled.div`
    width: fit-content;
    height: 100%;
    border-bottom: 1px solid #000;
    margin-left: 2em;

`;

const ContinuousDayCount = styled.span`
    margin: 0 0.5em;
    background-color: #fff;
    border-radius:10px;
    padding: 0em 1em 0em 1em;
    font-size: xx-large;
    @media (max-width: 900px) {
            font-size: 1em;
    }
`;
const CalendarContent = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 1em;

`;
const YearMonth = styled.div`
    width: 20%;
    height: 100%;
    display: block;
    align-items: center;
    justify-content: center;
`;
const CalenderYear = styled.div`
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    rt{
        font-size: large !important;
        @media (max-width: 900px) {
        font-size: 0.5em;
    }
    }
    @media (max-width: 900px) {
        font-size: 1em;
    }
`;
const CalenderMonth = styled.div`
    font-size: 4em;
    font-weight: bold;
    text-align: center;
    //rtタグのフォントサイズを調整する
    rt{
        font-size: large !important;
        @media (max-width: 900px) {
        font-size: 0.5em;
    }
    }
    @media (max-width: 900px) {
        font-size: 2em;
    }
`;
const CalendarArea = styled.div`
    width: 60%;
    height: 100%;
`;
const ButtonMonth = styled.div`
    width: 2em;
    height: 2em;    
    margin-right: 1em;
    &.prev{
        background-image: url('./img/button-prev.png');
        background-size: 100%;
        background-repeat: no-repeat;
    }
    &.next{
        background-image: url('./img/button-next.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 1em;
    }
    @media (max-width: 900px) {
        width: 1em;
        height: 1em;
    }
`;
const ModalDetail = styled.div`
    width: 80%;
    margin: 2em 0 2em 0;
    background-color: #fff;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    display: flex;
    .header{
        width: 30%;
        p{
            text-align: left;
            min-height: 2.5em;
            margin-top: 0.5em;
            padding-left: 0.5em;
            &.border{
                border-bottom: 2px dotted #000;
            }
            @media (max-width: 900px) {
                font-size: 0.5em;
            }
        }
    }
    .body{
        width: 70%;
        p{
            text-align: left;
            min-height: 2.5em;
            margin-top: 0.5em;
            &.border{
                border-bottom: 2px dotted #000;
            }
            @media (max-width: 900px) {
                font-size: 0.5em;
            }
        }
    }
`;
const ModalFooter = styled.div`
    position: sticky;
    bottom: -1px;
    background-color: #72ff70dc;
    width: 95%;
    margin: 0 auto;
`;
const Calendar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    //現在年、月を取得する
    const now = new Date();
    const nowYear = now.getFullYear();
    const nowMonth = now.getMonth() + 1;
    const [fcYear, setFcYear] = useState(nowYear);
    const [fcMonth, setFcMonth] = useState(nowMonth);

    const [calendars, setCalendars] = useState([]);
    const [calendarData, setCalendarData] = useState([]);
    const [calendarDayDatas, setCalendarDayDatas] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [longestContinueDays, setLongestContinueDays] = useState(0);
    const [continueDays, setContinueDays] = useState(0);

    /**
     * 記録情報の取得
     */
    const getCalendar = async () => {

        let query = {
            'year': fcYear,
            'month': fcMonth
        };
        console.log(query);
        const response = await axios.get(common.addAccessToken(process.env.REACT_APP_API_RECORD_CALENDAR_GET), { params: query });
        if (response) {
            console.log(response.data);
            setCalendars(response.data.data);
            setLongestContinueDays(response.data.longestContinueDays);
            setContinueDays(response.data.continueDays);

            //response.data.dataのkeyのみの配列を作成する
            let calendarDates = [];
            for (let key in response.data.data) {
                //keyがyyyy/mm/ddで来るためyyyy-mm-ddの形式にする
                key = key.replace(/\//g, '-');
                let temp = { start: key, title: '' };

                calendarDates.push(temp);
            }
            setCalendarData(calendarDates);
            console.log(JSON.stringify(calendarDates));
        }
    }

    useEffect(() => {
        getCalendar();
    }, []);

    /**
     * カレンダーの日付をクリックした時の処理
     */
    const handleDateClick = (arg: DateClickArg) => {
        //arg.dateStrの‐をスラッシュに変換する
        let date = arg.dateStr.replace(/-/g, '/');
        //日付をクリックした時に、その日付の運動データを取得する
        if (calendars[date]) {
            setCalendarDayDatas(calendars[date]);
            let jpDate = date.split('/');
            setSelectedDate(`${jpDate[0]}年{ねん}${jpDate[1]}月{がつ}${jpDate[2]}日{にち}`);
            setIsModalOpen(true);
        }
    };

    const moveMonth = (direction: 'prev' | 'next' | 'today') => {
        //prevの時は.fc-prev-buttonをクリックする
        const button = document.querySelector(`.fc-${direction}-button`);
        if (button) {
            button.click();
        }
        //.fc-toolbar-titleの中身を取得する
        const title = document.querySelector('.fc-toolbar-title');
        //年月を分割する
        if (title) {
            const titleText = title.textContent;
            if (titleText) {
                //yyyy年mm月の形式で取得されるので、分割する
                const titleArray = titleText.split('年');
                if (titleArray.length === 2) {
                    const year = titleArray[0];
                    const month = titleArray[1].replace('月', '');
                    setFcYear(year);
                    setFcMonth(month);
                }
            }
        }

    };
    return (
        <>
            <ContinuousDays>
                <ContinuousDaysChild><RubyText text="ただ今の継続日数{けいぞくにっすう}"></RubyText><ContinuousDayCount>{longestContinueDays}</ContinuousDayCount><RubyText text="日{にち}"></RubyText></ContinuousDaysChild>
                <ContinuousDaysChild><RubyText text="これまでの最長継続日数{さいちょうけいぞくにっすう}"></RubyText><ContinuousDayCount>{continueDays}</ContinuousDayCount><RubyText text="日{にち}"></RubyText></ContinuousDaysChild>
            </ContinuousDays>
            <CalendarContent>

                <YearMonth>

                    <CalenderYear><RubyText text={`${fcYear}年{ねん}`}></RubyText></CalenderYear>
                    <CalenderMonth><RubyText text={`${fcMonth}月{がつ}`}></RubyText></CalenderMonth>
                    {/* <Button.Default onClick={() => { moveMonth('today') }}><RubyText text="今月{こんげつ}を表示{ひょうじ}する"></RubyText></Button.Default> */}
                </YearMonth>

                <ButtonMonth className="prev" onClick={() => { moveMonth('prev') }}></ButtonMonth>
                <CalendarArea>
                    {calendarData.length > 0 &&
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            locales={[jaLocale]}
                            locale='ja'
                            dateClick={handleDateClick}
                            events={calendarData}
                        />
                    }
                </CalendarArea>
                <ButtonMonth className="next" onClick={() => { moveMonth('next') }}></ButtonMonth>
            </CalendarContent>
            {
                isModalOpen &&
                <Modal className="bg-green half">
                    <h2><RubyText text={`${selectedDate}の運動{うんどう}データ`}></RubyText></h2>
                    {
                        ['ranking', 'enjoy', 'mypace', 'hapihapi'].map((course, index) => {
                            return (
                                calendarDayDatas[course].map((data, index) => {
                                    return (
                                        <ModalDetail key={index}>
                                            <div className="header">
                                                <p className="border"><RubyText text="選択{せんたく}コース"></RubyText></p>
                                                <p className="border"><RubyText text="種目{しゅもく}"></RubyText></p>
                                                <p><RubyText text="記録{きろく}"></RubyText></p>
                                            </div>
                                            <div className="body">
                                                <p className="border">{course == 'ranking' ? 'ランキングコース' : course == 'enjoy' ? 'エンジョイコース' : course == 'mypace' ? 'マイペースコース' : course == 'hapihapi' ? 'はぴはぴコース' : ''}</p>
                                                <p className="border"><RubyText text={data.competition_name}></RubyText></p>
                                                <p>{data.score}</p>
                                            </div>
                                        </ModalDetail>
                                    );
                                }
                                )
                            );
                        }
                        )
                    }
                    <ModalFooter>
                    <Button.Default className="modal-close" onClick={() => { setIsModalOpen(false) }}>とじる</Button.Default>
                    </ModalFooter>
                </Modal>
            }
        </>
    );
}
export default Calendar;