import React, { useEffect } from "react";
import styled from "styled-components";
import { UseUserLand } from "../../../providers/UserProvider.tsx";
import { useState } from "react";
import Particles from "react-tsparticles";
import axios from "axios";
import RubyText from "../../../components/RubyText.tsx";

const TitleContainer = styled.div`
    width:fit-content;
    background-color: #1deb50cf;
    border-radius: 10px;
    border: 3px solid #fff;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 1rem;
    @media (max-width: 900px) {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        h1{
            font-size: 1rem;
            
        }
    }
`;
const Background = styled.div<{ src: string }>`
    width: 100%;
    height: 100%;
    background: url(${props => props.src});
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;
    text-align:left;
`;

const BackGroundItem = styled.div<{ src: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    right:0;
    margin: auto;
    background: transparent;
    z-index: 0;
    //background-imageをpropsで受け取り、設定する
    background-image: url(${props => props.src});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    //backgroundURLが空の場合は、display:noneにする
    display: ${props => props.src != '' ? 'block' : 'none'};
`;
const ParticleContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    right:0;
    margin: auto;
    canvas{
        position: absolute !important;
    }
`;


const CharacterContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    /* border: 5px dotted #fcff6a; */
    top:0;
    right:0;
    margin: auto;
`;
const CharacterEffect = styled.div<{ className: string, src: string }>`
    width: 100%;
    height:100%;
    margin: auto;
    background: url(${props => props.src});
    background-repeat:  no-repeat;
    background-size: contain;
    background-position: bottom;
    position: absolute;
    bottom:15%;
`;

const CharacterImage = styled.div<{ src: string }>`
    background: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    height:100%;
    width:100%;
    position: absolute;
    bottom:0;
`;
const LandGrid = styled.table`
    height: 50%;
    width:60%;
    border-collapse: collapse;
    /* transform: perspective(1800px) translateY(0%) rotateX(0deg); */
    margin: auto;
    //tbody要素に対して、transformを設定
    tbody {
        //transform: perspective(1800px) translateY(0%) rotateX(45deg);
    }
    position: absolute;
    bottom:0;
    left:20%;
    z-index: 1;
`;
const GridItem = styled.td<{ $overlay: boolean }>`
    transform: perspective(250px) translateY(0%) rotateX(0deg) skew(0deg);
    filter: drop-shadow(0px 10px 0px #0000008f);
    transform-origin: bottom center;
    position: sticky;
    //overlayがtrueの場合は、z-indexを1にする
    z-index: ${props => props.$overlay ? 1 : -1};
`;
const GridItemImg = styled.img<{ src: string }>`
    width: 100%;
    height: 100%;
    border:none;
    z-index: 0;
    //srcを設定
    src: ${props => props.src};
    //srcが空の場合は、display:noneにする
    display: ${props => props.src ? 'block' : 'none'};
`;
// ランドグリッドコンテナ
export const PreviewContainer = (props) => {
    const [userLand, setUserLand] = useState<{}>({});
    const [particlesOptions, setParticlesOptions] = useState(null);
    const [particlesExist, setParticlesExist] = useState(false);
    useEffect(() => {
        setUserLand(props.land);
        console.log(props.land);
        axios.get(props.land.particle)
            .then((response) => {
                //フルスクリーンを無効化
                response.data.fullScreen = { enable: false };
                setParticlesOptions(response.data);
                setParticlesExist(true);
            })
            .catch((error) => {
                console.log(error);
                setParticlesExist(false);
            });


    }, [props.land]);
    return (
        <>
            {
                // ユーザー情報が未設定の場合は、パスワード変更が必須
                !userLand &&
                <></>
            }
            <Background src={userLand.background}>
                <BackGroundItem className="back_item" src={userLand.back_item} />
                <TitleContainer>
                    <h1><RubyText text="マイひろば設定{せってい}"></RubyText></h1>
                    <p><RubyText text="マイひろばをカスタマイズしよう！"></RubyText></p>
                </TitleContainer>
                {props.children}
                <ParticleContainer >
                    {
                        particlesExist && (
                            <Particles options={particlesOptions} />
                        )
                    }
                </ParticleContainer>
                <LandGrid className="LandGrid">
                    <thead><tr><td>
                        <CharacterContainer>
                            <CharacterEffect className="CharacterEffect" src={userLand.character_effect}>
                                <CharacterImage src={userLand.character} />
                            </CharacterEffect>
                        </CharacterContainer>
                    </td></tr></thead>
                    <tbody>
                        <tr>
                            <GridItem $overlay={false}><GridItemImg src={userLand.land_item?.x1y1} className="row1" /></GridItem>
                            <GridItem $overlay={false}><GridItemImg src={userLand.land_item?.x2y1} className="row1" /></GridItem>
                            <GridItem $overlay={false}><GridItemImg src={userLand.land_item?.x3y1} className="row1" /></GridItem>
                            <GridItem $overlay={false}><GridItemImg src={userLand.land_item?.x4y1} className="row1" /></GridItem>
                            <GridItem $overlay={false}><GridItemImg src={userLand.land_item?.x5y1} className="row1" /></GridItem>
                            <GridItem $overlay={false}><GridItemImg src={userLand.land_item?.x6y1} className="row1" /></GridItem>
                        </tr>
                        <tr>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x1y2} className="row2" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x2y2} className="row2" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x3y2} className="row2" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x4y2} className="row2" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x5y2} className="row2" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x6y2} className="row" /></GridItem>
                        </tr>
                        <tr>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x1y3} className="row3" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x2y3} className="row3" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x3y3} className="row3" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x4y3} className="row3" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x5y3} className="row3" /></GridItem>
                            <GridItem $overlay={true}><GridItemImg src={userLand.land_item?.x6y3} className="row3" /></GridItem>
                        </tr>

                    </tbody>

                </LandGrid>

            </Background>
        </>
    )
};