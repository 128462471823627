import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { KeyBoard } from "./KeyBoard.tsx";

const InputDiv = styled.div<{ className?: string }>`
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Label = styled.span`
    margin-right: 10px;
    font-weight: bold;
    min-width: 150px;
`;

const TextInput = styled.input`
    width:calc(100% - 150px);
    height:1rem;
    padding: 10px;
    font-size: 1em;
    font-weight: bold;
    border: 2px solid #272727;
    @media (max-width: 900px) {
        font-size: 1em;
        height:0.5em;
    }
`;

const InputUnit = styled.span`
    margin-left: 10px;
    font-weight: bold;
    @media (max-width: 900px) {
        font-size: 0.5em;
    }
`;

const KeyboardInput = styled.div`
    width:calc(100% - 150px);
    height:1rem;
    background-color: #fff;
    padding: 10px;
    font-size: 1em;
    font-weight: bold;
    border: 2px solid #272727;
    text-align: right;
`;

const FuriganaText = (text: string) => {
    const convertFurigana = (str: string) => {
        // 漢字の範囲を簡易的に指定する正規表現
        // このパターンはすべての漢字を網羅していない可能性があるため、必要に応じて調整してください
        const regex = /([\u3400-\u9FBF]+)\{(.+?)\}/g;
        const convertedText = str.replace(regex, '<ruby>$1<rt>$2</rt></ruby>');
        return convertedText;
    };

    return <span dangerouslySetInnerHTML={{ __html: convertFurigana(text) }} />;
};

export default function InputText(props: {
    Label?: string,
    InitialValue?: any,
    OnChangeText: (value: any) => void,
    Name?: string,
    Type?: string | 'integer',
    Value?: any,
    PlaceHolder?: string,
    className?: string,
    children?: React.JSX.Element,
    useCustomKeyboard?: boolean,
    Unit?: string,

}) {

    const [opened, IsOpened] = useState<boolean>(false);
    const [text, setText] = useState<string>(props.InitialValue ?? '');

    useEffect(() => {

        props.OnChangeText(text);

    }, [text]);

    function OnChangeText(e: React.ChangeEvent<HTMLInputElement>) {
        setText(e.target.value);
    }

    return (
        <>
            <InputDiv className={props.className} onClick={() => props.useCustomKeyboard && IsOpened(true)}>
                {props.Label && <Label>{FuriganaText(props.Label)}</Label>}
                {

                    // 通常のテキスト入力フィールド
                    !props.useCustomKeyboard &&
                    <>

                        <TextInput
                            type={props.Type}
                            name={props.Name}
                            value={text}
                            onChange={e => OnChangeText(e)}
                            placeholder={props.PlaceHolder}
                        />

                    </>


                }
                {
                    // キーボード表示時の背景DIV
                    props.useCustomKeyboard &&
                    <>
                        <KeyboardInput>{text}</KeyboardInput>
                    </>
                }
                {
                    // 単位の表示
                    props.Unit && <InputUnit>{props.Unit}</InputUnit>
                }
                {
                    // カスタムキーボードの使用を選択
                    props.useCustomKeyboard && opened && (
                        <KeyBoard
                            InitialValue={text}
                            OnClose={() => { IsOpened(false) }}
                            OnSetText={setText}
                            Type={props.Type}
                        />
                    )
                }
            </InputDiv>
        </>
    );
}
