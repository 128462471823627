import React, { useState } from "react";
import InputAmbition from "./InputAmbition.tsx";
import { useLocation } from "react-router-dom";
import InputRecord from "./InputRecord.tsx";
import { Competition } from "../../models/Competition.ts";

export const InputValue = () => {

    const location = useLocation();
    const urlParams = location.state as { showSetAmbition: boolean, competition: Competition };

    const [showSetAmbition, setShowSetAmbition] = useState<boolean>(urlParams.showSetAmbition);

    const competition = urlParams.competition;

    const setAmbition = () => setShowSetAmbition(false);
    const resetAmbition = () => setShowSetAmbition(true);


    return (
        showSetAmbition ?
            <InputAmbition OnSetEvent={setAmbition} Competition={competition} /> :
            <InputRecord OnSetEvent={resetAmbition} Competition={competition} />
    )
}