import styled from "styled-components";

export const HeaderContainer = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
`;